import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from '../../common/components/routing/Router';
import { Path } from '../container/Path';
import { Container as PageContainer } from "../../index";
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { DataProvider } from '../../common/model/DataProvider';
import { IUploadStatus, UploadStatus } from '../models/UploadStatus';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import Util from '../custom/Util';

@withRouter(Path.UPLOAD_STATUS.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString()], Identity, Path.AFFILIATES_STOCK)
class UploadStatusPage extends VlbPage {

  private model = new UploadStatus(false);
  private dataProvider = new DataProvider<IUploadStatus>();

  constructor(props: any) {
    super(props);

    this.state = {
      modalFile: false,
      currentModelFile: '',
    }
  }

  pageTitle() {
    return "Upload Status" + super.pageTitle();
  }

  getDestroyableMembers() {
    return [
    ];
  }

  errorLog(row?: any) {
    if (row.error_log) {
      this.setState(prevState => ({
        modalFile: !prevState.modalFile,
        currentModelFile: row
      }));
    } else {
      Util.notification("info", "No information", 1500)
    }
  }

  renderContent() {

    let errors = null;

    if (this.state.currentModelFile) {
      let values = Object.values(JSON.parse(this.state.currentModelFile.error_log));
      let keys = Object.keys(JSON.parse(this.state.currentModelFile.error_log));
      let li = [];

      for (let i = 0; i < values.length; i++) {

        li.push(<li key={keys[i]}>{values[i]}</li>);

      }
      errors = <ul>{li}</ul>;

    }

    let errorLog;
    errorLog = (
      <Modal centered size="lg" isOpen={this.state.modalFile} fade={false} toggle={() => this.setState({ modalFile: !this.state.modalFile })}>
        <ModalHeader toggle={() => this.setState({ modalFile: !this.state.modalFile })}>{this.state.currentModelFile.affiliate ? this.state.currentModelFile.affiliate.name : null} log</ModalHeader>
        <ModalBody>
          {errors}
        </ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalFile: !this.state.modalFile })}>Close</Button>
        </ModalFooter>
      </Modal>
    )

    const uploadStatusesColumns: any[] = [
      new DataColumn('Last upload date', 'last_upload_date', 25, new BasicSorter()),
      new DataColumn('Number of articles uploaded', 'uploaded_article_count', 20, new BasicSorter()),
      new DataColumn('Number of errors', 'error_count', 15, new BasicSorter()),
      new ActionColumn("", null, null, 10,
        [{
          operationCb: (row: any) => {
            this.errorLog(row)
          },
          iconClassName: "table-edit view",
          message: null,
          icon: <FontAwesomeIcon icon={faInfoCircle} />,
          iconStyle: {}
        }],
        "Error log"
      ),
    ];

    const paging = new BasicPaging({
      pageSize: 15
    });

    return (
      <Container fluid className="page-content">

        <div className="title-box">
          <h1 className="page-title">Upload status</h1>
        </div>

        <div className="element-box">
          <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
            provider: this.dataProvider,
            loader: [4, 15],
            searchCallback: (params: IRestDataSourceParams): void => {
              this.dataProvider.refreshWithHeaders(
                this.model.getListPlain({
                  ...params,
                  sort: {
                    attr: "last_upload_date",
                    ascending: false
                  }
                })
              )
            },
            columns: uploadStatusesColumns,
            paging: paging
          })} />
        </div>

        {errorLog}

      </Container>
    );
  }
}

export default UploadStatusPage;

