import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from '../../common/components/routing/Router';
import { Path } from '../container/Path';
import { Container as PageContainer } from "../../index";
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';
import { Container, Row, Col, Button, Collapse, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faInfoCircle, faTimesCircle, faCheckCircle, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faSortUp } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { Article, IArticle } from '../models/Article';
import { DataProvider } from '../../common/model/DataProvider';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import { ExportExcelArticle as ExportExcel } from '../custom/ExportExcelArticle';
import { AffiliateContact } from '../models/AffiliateContact';
import { AxiosError } from 'axios';
import Util from '../custom/Util';
import { Search } from '../models/Search';
import { Affiliate, IAffiliate } from '../models/Affiliate';
import '../../assets/css/customStyles.css';
import { CheckboxInput } from '../../common/components/widgets/form/input/CheckboxInput';
import { SelectInput } from '../../common/components/widgets/form/input/SelectInput';
import { NumberInput } from '../../common/components/widgets/form/input/NumberInput';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { FormInput } from '../../common/components/widgets/form/FormInput';

@withRouter(Path.AFFILIATES_STOCK.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString(), Role.USER.toString()], Identity, Path.LOGIN)
class AffiliateStockPage extends VlbPage {

  private dataProvider = new DataProvider<IArticle>();

  private contactModel = new AffiliateContact(false);
  private searchModel = new Search(false);
  private affiliateModel = new Affiliate(false);
  private articleModel = new Article(false);

  private pageNumber: number = 1;
  private submitted: boolean = false;
  private initialLoad: boolean = true;

  private inputs: { [attr: string]: React.RefObject<any> } = {
    size_short: React.createRef(),
    description: React.createRef(),
    affiliate: React.createRef(),
    affiliate_id: React.createRef(),
    available_stock: React.createRef(),
    code: React.createRef(),
    grade: React.createRef(),
    od_1: React.createRef(),
    od_2: React.createRef(),
    wt_1: React.createRef(),
    wt_2: React.createRef(),
    type: React.createRef(),
    specification: React.createRef(),
    ends: React.createRef(),
    other: React.createRef(),
    surface: React.createRef(),
    certificate: React.createRef(),
    heat_treatment: React.createRef(),
  };

  private autocompleteValues: { [attr: string]: string } = {
    od_1: "",
    od_2: "",
    wt_1: "",
    wt_2: "",
    affiliate: "",
    type: "",
    grade: "",
    specification: "",
    ends: "",
    surface: "",
    certificate: "",
    heat_treatment: "",
    other: ""
  };

  private originalInputValue: string = "";

  constructor(props: any) {
    super(props);

    this.detailedSearch = this.detailedSearch.bind(this);
    this.affiliate = this.affiliate.bind(this);
    this.articleDetails = this.articleDetails.bind(this);
    this.tableOptions = this.tableOptions.bind(this);
    this.selectExport = this.selectExport.bind(this);
    this.clearSelections = this.clearSelections.bind(this);
    this.onSearchInputChange = this.debounced(300, this.onSearchInputChange.bind(this));;
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchCallback = this.searchCallback.bind(this);
    this.readInputs = this.readInputs.bind(this);
    this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
    this.search = this.search.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputFocusLost = this.onInputFocusLost.bind(this);

    this.state = {
      pageNumber: 1,

      loading: true,

      isDetailedSearchOpen: false,
      isAffilateOpen: false,
      isArticleDetailseOpen: false,
      isTableOptionsOpen: true,

      affiliates: [],
      affiliate: null,
      modalContactList: [],

      articleDetails: [],
      selectedList: [],

      // search lists
      size_short: [],
      size_shortStart: [],
      description: [],
      descriptionStart: [],
      affiliate_id: [],
      affiliate_idStart: [],
      type: [],
      typeStart: [],
      grade: [],
      gradeStart: [],
      specification: [],
      specificationStart: [],
      ends: [],
      endsStart: [],
      surface: [],
      surfaceStart: [],
      other: [],
      otherStart: [],
      certificate: [],
      certificateStart: [],
      heat_treatment: [],
      heat_treatmentStart: [],

    }

    this.handleEnter = this.handleEnter.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }


  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleEnter(event) {
    this.handleSubmit(event, true)
  }
  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.handleEnter(event);
    }
  }

  pageTitle() {
    return "Affiliate stock" + super.pageTitle();
  }

  getDestroyableMembers() {
    return [
    ];
  }

  detailedSearch() {
    this.setState(prevState => ({
      isDetailedSearchOpen: !prevState.isDetailedSearchOpen,
    }));
  }

  affiliate(row: IArticle) {

    this.setState(prevState => ({
      isAffilateOpen: !prevState.isAffilateOpen,
      affiliates: row
    }));

    this.contactModel.loadContactsById(row.affiliate_id)
      .then((response: any) => {
        this.setState({
          modalContactList: response
        })
      })
      .catch((error: AxiosError) => {
        Util.notification("error", "Contacts could not be loaded", 1500);
      });

  }

  articleDetails(row: IArticle) {
    this.setState(prevState => ({
      isArticleDetailseOpen: !prevState.isArticleDetailseOpen,
      articleDetails: row
    }));
  }

  tableOptions() {
    this.setState(prevState => ({
      isTableOptionsOpen: !prevState.isTableOptionsOpen,
    }));
  }

  onInputFocus(attribute: string) {
    let originalValue = this.autocompleteValues[attribute];
    if (originalValue !== undefined && originalValue !== null) this.originalInputValue = originalValue;
    else this.originalInputValue = "";
  }

  onInputFocusLost(attribute: string) {
    if (this.originalInputValue !== this.getValueFromInputField(attribute)) {
      for (const key in this.autocompleteValues) {
        if (key === attribute) continue;
        this.loadListForAutocomplete(key, this.autocompleteValues[key]);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
    for (const key in this.autocompleteValues) {
      this.autocompleteValues[key] = "";
      this.loadListForAutocomplete(key, "");
    }
    super.componentDidMount();
  }


  onSearchChange(attr, passToAutocomplete, selectedOption?, actionTaken?) {
    if (passToAutocomplete === true && selectedOption !== null && selectedOption.value !== undefined && this.getValueFromInputField(attr).length > 0) {
      this.onSearchInputChange(attr, this.getValueFromInputField(attr));
    }
    if (attr == "affiliate") {
      attr = "affiliate_id";
    }
    var stateObject = function () {
      let returnObj = {};
      returnObj[attr] = this.state[attr + "Start"];
      return returnObj;
    }.bind(this)();

    this.setState(stateObject);
  }


  debounced(delay, fn) {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    }
  }

  loadListForAutocomplete(attr, selectedOption) {
    this.searchModel.autocomplete(attr, selectedOption).then((response) => {
      if (attr === "affiliate") {
        let res: IAffiliate[] = response.data.result;
        let list = [];

        //mapiranje podataka za Select

        list = res.map((one: IAffiliate) => {
          return { value: one.id, label: one.name }
        })
  
        this.setState({
          affiliate_id: list
        });
      } else {
        let res: IArticle[] = response.data.result;
        let list = [], identifier = "";
  
        //mapiranje podataka za Select
  
        list = res.map((one: IArticle) => {
          identifier = one[attr];
          return { value: identifier, label: identifier }
        })
  
        // dodjeljivanje vrijednosti listama iz state-a koje se koriste u selectu
        // dinamički se postavljaju vrijednosti
        var stateObject = function () {
          let returnObj = {};
          returnObj[attr] = list;
          return returnObj;
        }.bind(this)();
  
        this.setState(stateObject);
      }
    });
  }

  getValueFromInputField(attr: string): string {
    let value;
    if (attr === 'affiliate' || attr === 'affiliate_id') {
      if (this.inputs.hasOwnProperty('affiliate_id') && this.inputs['affiliate_id'].current && this.inputs['affiliate_id'].current.selectedOption) {
        value = this.inputs['affiliate_id'].current.selectedOption.label;
      }
    } else {
      if (this.inputs.hasOwnProperty(attr) && this.inputs[attr].current) {
        value = this.inputs[attr].current.getValue();
      }
    }
    if (value === undefined || value === null) return "";
    else if (attr === 'od_1' || attr === 'od_2' || attr === 'wt_1' || attr === 'wt_2') { 
      return value.replace(',', '.').trim();
    }
    else return value.trim();
  }

  onSearchInputChange(attr, selectedOption?: any) {
    if (this.autocompleteValues[attr] === undefined) {
      return;
    }

      //Solve an edge case where when the select input goes out of focus it calls this function with '' as value, but the actual input will still hold the chosen value, for affiliate value is the label
    let actualInputValue = this.getValueFromInputField(attr);
    if (selectedOption == '' && actualInputValue !== null) {
      selectedOption = actualInputValue;
    }

    if (this.autocompleteValues[attr] !== selectedOption) {
      this.autocompleteValues[attr] = selectedOption;
    } else {
      return;
    }

    this.loadListForAutocomplete(attr, selectedOption);
  }

  private readInputs(): any {
    const plainObject = {};
    const inputs = this.inputs;

    for (const key in inputs) {
      if (inputs.hasOwnProperty(key) && inputs[key].current) {
        if (key === 'available_stock') {
          if (inputs[key].current.getValue() === true) {
            plainObject[key] = 0;
          } else {
            plainObject[key] = null;
          }
        } else {
          plainObject[key] = inputs[key].current.getValue();
          inputs[key].current.removeError();
        }
      }
    }

    this.searchModel.setFromPlainObject({ ...this.searchModel.asPlainObject(), ...plainObject });

    return plainObject;
  }


  private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key) && inputs[key] !== undefined && inputs[key].current) {
        inputs[key].current.setError(errors[key]);
      }
    }
  }


  private validate(event: any): boolean {
    this.readInputs();

    let inputs = this.inputs;
    const valid = this.searchModel.validate();

    if (this.submitted && !valid) {
      const errors: { [attr: string]: string } = this.searchModel.getErrors();
      this.fillInputsWithErrors(errors, inputs);
    }

    event.preventDefault();

    return valid;
  }

  handleSubmit(event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | React.FormEvent, realSubmit?: boolean) {

    if (realSubmit) {
      this.pageNumber = 1;
    }

    this.submitted = true;

    if (this.validate(event)) {
      this.forceUpdate()
    }
  }

  searchCallback(params: IRestDataSourceParams) {

    let currentParams = params;

    //We need to check here if the params are changed, because rerender causes  unnecessary 
    // requests whih leads to glitches in data table

    //In short, search request is sent on initial load, on submit, and when page number changes

    if (this.submitted) {
      currentParams = {
        ...params,
        paging: {
          pageNumber: params.paging.pageNumber,
          pageSize: params.paging.pageSize,
        }
      };

      this.pageNumber = params.paging.pageNumber;
      if ((JSON.stringify(params) !== JSON.stringify(currentParams))) {
        this.search(currentParams)
      }

    } else {
      currentParams = {
        ...params
      };

      if (this.initialLoad || this.pageNumber != params.paging.pageNumber || params.sort) {
        this.initialLoad = false;
        this.pageNumber = params.paging.pageNumber;
        this.search(currentParams)
      }
    }
  };

  search(currentParams) {

    this.dataProvider.refreshWithHeaders(
      this.searchModel.search(this.searchModel.asPlainObject(), currentParams)
        .then(response => {

          response.data = response.data.map((article: IArticle) => {
            // formatiranje weight per uom
            if (article.weight) {
              article.weight = parseFloat(article.weight.toString()).toFixed(2);
            }
            // formatiranje outgoing
            if (article.out_going) {
              article.out_going = article.out_going == 'No' ? null : 'Y';
            }

            // formatiranje size
            if (!article.size || article.size == "") {

              article.size = (article.od_1 && article.od_1 !== '0.0') ? article.od_1 : "";

              if (article.size !== "" && article.od_2) {
                article.size += " X ";
              }

              article.size += (article.od_2 && article.od_2 !== '0.0') ? article.od_2 : "";

              if (article.size !== "" && article.wt_1) {
                article.size += " X ";
              }

              article.size += (article.wt_1 && article.wt_1 !== '0.0') ? article.wt_1 : "";

              if (article.size !== "") {
                article.size += " MM";
              }
            } else {
              if (article.size.indexOf("MM") == -1 && /^\d+$/.test(article.affiliate.stock_file_code)) {
                article.size += " MM";
              }
            }
            return article;
          })

          this.submitted = false;
          return response;
        }))
  }


  selectExport(row: IArticle | null) {
    if (this.state.selectedList.find((item: IArticle) => item.id === row.id)) {
      let filtered = this.state.selectedList.filter((item: IArticle) => item.id !== row.id); //ako row.id postoji u state-u izbriši ga
      this.setState({ selectedList: filtered })
    } else {
      let joined = this.state.selectedList.concat(row); //ako row.id ne postoji u state-u izbriši ga
      this.setState({ selectedList: joined })
    }
  }

  clearSelections() {
    this.setState({
      selectedList: []
    })
  }


  renderContent() {

    let affiliate = (
      <Modal size="lg" isOpen={this.state.isAffilateOpen} toggle={this.affiliate} className="fs-09">
        <ModalHeader toggle={this.affiliate}>
          {this.state.affiliates.affiliate ? this.state.affiliates.affiliate.name : null}
        </ModalHeader>
        <ModalBody>
          <h2>Affiliate contact</h2>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Contact preson</th>
                <th>Support scope information</th>
                <th>Email address</th>
                <th>Telephone nr.</th>
              </tr>
            </thead>
            <tbody>
              {this.state.modalContactList.map(single => (
                <tr key={single.id}>
                  <td>{single.name}</td>
                  <td>{single.additional_info}</td>
                  <td>{single.email}</td>
                  <td>{single.phone_number}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h2 className="mt-3">Intercompany pricing information</h2>
          <p className="fs-09">
            {this.state.affiliates.affiliate ? (this.state.affiliates.affiliate.intercompany_price_information ? this.state.affiliates.affiliate.intercompany_price_information : 'No information') : null}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.affiliate}>Close</Button>
        </ModalFooter>
      </Modal>
    )

    let articleDetails = (
      <Modal size="lg" isOpen={this.state.isArticleDetailseOpen} toggle={this.articleDetails} className="fs-09">
        <ModalHeader toggle={this.articleDetails}>{this.state.articleDetails.affiliate ? this.state.articleDetails.affiliate.name : null}</ModalHeader>
        <ModalBody>
          <h2>Article details</h2>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Article attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Outside diameter 1</th>
                <td>{this.state.articleDetails.od_1 && this.state.articleDetails.od_1 != '0.0' ? this.state.articleDetails.od_1 : ""}</td>
              </tr>
              <tr>
                <th>Outside diameter 2</th>
                <td>{this.state.articleDetails.od_2 && this.state.articleDetails.od_2 != '0.0' ? this.state.articleDetails.od_2 : ""}</td>
              </tr>
              <tr>
                <th>Outside diameter 3</th>
                <td>{this.state.articleDetails.od_3 && this.state.articleDetails.od_3 != '0.0' ? this.state.articleDetails.od_3 : ""}</td>
              </tr>
              <tr>
                <th>Wall thickness 1</th>
                <td>{this.state.articleDetails.wt_1 && this.state.articleDetails.wt_1 != '0.0' ? this.state.articleDetails.wt_1 : ""}</td>
              </tr>
              <tr>
                <th>Wall thickness 2</th>
                <td>{this.state.articleDetails.wt_2 && this.state.articleDetails.wt_2 != '0.0' ? this.state.articleDetails.wt_2 : ""}</td>
              </tr>
              <tr>
                <th>Article type</th>
                <td>{this.state.articleDetails.type}</td>
              </tr>
              <tr>
                <th>Specification</th>
                <td>{this.state.articleDetails.specification}</td>
              </tr>
              <tr>
                <th>Grade</th>
                <td>{this.state.articleDetails.grade}</td>
              </tr>
              <tr>
                <th>Heat treatment</th>
                <td>{this.state.articleDetails.heat_treatment}</td>
              </tr>
              <tr>
                <th>Length</th>
                <td>{this.state.articleDetails.length != '0.0' ? this.state.articleDetails.length : null}</td>
              </tr>
              <tr>
                <th>Ends</th>
                <td>{this.state.articleDetails.ends}</td>
              </tr>
              <tr>
                <th>Surface</th>
                <td>{this.state.articleDetails.surface}</td>
              </tr>
              <tr>
                <th>CDI</th>
                <td>{this.state.articleDetails.cdi ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />}</td>
              </tr>
              <tr>
                <th>Supplier approved</th>
                <td>{this.state.articleDetails.supplier_approved ? <FontAwesomeIcon icon={faCheckCircle} /> : (this.state.articleDetails.supplier_approved != null ? <FontAwesomeIcon icon={faTimesCircle} /> : null)}</td>
              </tr>
              <tr>
                <th>Affiliate</th>
                <td>{this.state.articleDetails.affiliate ? this.state.articleDetails.affiliate.name : null}</td>
              </tr>
              <tr>
                <th>Certificates</th>
                <td>{this.state.articleDetails.certificate}</td>
              </tr>
              <tr>
                <th>Others</th>
                <td>{this.state.articleDetails.other}</td>
              </tr>
              <tr>
                <th>Outgoing</th>
                <td>{this.state.articleDetails.out_going}</td>
              </tr>
              <tr>
                <th>Version</th>
                <td>{this.state.articleDetails.version}</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.articleDetails}>Close</Button>
        </ModalFooter>
      </Modal>
    )

    const articleColumns: any[] = [
      new DataColumn('Size', 'size', 15, new BasicSorter()),
      new DataColumn('Article description', 'description', 25, new BasicSorter()),
      new DataColumn('Affiliate', 'affiliate.name', 10, new BasicSorter()),
      new DataColumn('Article number', 'code', 10, new BasicSorter()),
      new DataColumn('Available stock quantity', 'available_stock', 5, new BasicSorter()),
      new DataColumn('U.O.M.', 'uom', 5, new BasicSorter()),
      new DataColumn('Out going', 'out_going', 5, new BasicSorter()),
      new DataColumn('Weight per UOM', 'weight', 5, new BasicSorter()),
      new DataColumn('Cost price', 'cost_price', 5, new BasicSorter()),
      new DataColumn('Curr.', 'affiliate.currency_cost_prices', 5, new BasicSorter()),
      new DataColumn('RV', 'replacement_value', 5, new BasicSorter()),
    ];

    articleColumns.push(
      new ActionColumn("", null, null, 10,
        [{
          operationCb: (row: any) => {
            this.affiliate(row)
          },
          iconClassName: "table-edit view contact",
          message: null,
          icon: <FontAwesomeIcon icon={faAddressCard} />,
          iconStyle: {}
        },
        {
          operationCb: (row: any) => {
            this.articleDetails(row)
          },
          iconClassName: "table-edit view info",
          message: null,
          icon: <FontAwesomeIcon icon={faInfoCircle} />,
          iconStyle: {}
        }],
        "Actions"
      ),

      new ActionColumn("", null, null, 10,
        [{
          operationCb: (row: any, ) => {
            this.selectExport(row)
          },
          iconClassName: "table-edit selectzone",
          message: null,
          icon: <><FontAwesomeIcon className="tableSquare" icon={faSquare} /> <FontAwesomeIcon className="tableCheckSquare" icon={faCheckSquare} /></>,
          iconStyle: {},
          selectedList: this.state.selectedList
        }],
        "Export Excel"
      ),
    )

    const paging = new BasicPaging({
      pageNumber: this.pageNumber,
      pageSize: 50
    });

    return (
      <Container fluid className="page-content">

        <div className="title-box">
          <h1 className="page-title">Affiliate stock</h1>
        </div>

        <Form className="search_form" onSubmit={(e) => this.handleSubmit(e, true)} >
          <Container fluid className="element-box">
            <Row>
              <Col xs="6">
                <h2>Search</h2>
              </Col>
              <Col xs="6">
                <Button color="detailed-search" className="p-0 d-block ml-auto mr-0 fs-09" onClick={this.detailedSearch}>Detailed search {this.state.isDetailedSearchOpen ? <FontAwesomeIcon className="position-relative icon-position-up" icon={faSortUp} /> : <FontAwesomeIcon className="position-relative icon-position-down" icon={faSortDown} />} </Button>
              </Col>

              <Col md="3">
                <FormGroup >
                  <SelectInput
                    creatable={true}
                    options={this.state.size_short}
                    id="size"
                    placeholder="Size"
                    onInputChange={(e) => this.onSearchInputChange("size_short", e)}
                    onChange={(e) => this.onSearchChange("size_short", e)}
                    ref={this.inputs.size_short}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <TextInput
                    id="desc"
                    placeholder="Article description"
                    onChange={(e) => this.onSearchChange("description", e)}
                    ref={this.inputs.description}
                    showCloseIcon={true}
                  />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup>
                  <TextInput
                    id="code"
                    placeholder="Article number"
                    onChange={(e) => this.forceUpdate()}
                    ref={this.inputs.code}
                    showCloseIcon={true}
                  />
                </FormGroup>
              </Col>

              <Col md="3">
                <FormGroup>
                  <SelectInput
                    options={this.state.affiliate_id}
                    id="affiliate"
                    placeholder="Affiliate"
                    onInputChange={(e) => this.onSearchInputChange("affiliate", e)}
                    onChange={(e, actionTaken) => this.onSearchChange("affiliate", true, e, actionTaken)}
                    onFocus={() => this.onInputFocus("affiliate")}
                    onBlur={() => this.onInputFocusLost("affiliate")}
                    ref={this.inputs.affiliate_id}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <Collapse isOpen={this.state.isDetailedSearchOpen}>

                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.od_1}
                          id="od_1"
                          placeholder="Outside diameter 1"
                          onInputChange={(e) => this.onSearchInputChange("od_1", e)}
                          onChange={(e) => this.onSearchChange("od_1", true, e)}
                          onFocus={() => this.onInputFocus("od_1")}
                          onBlur={() => this.onInputFocusLost("od_1")}
                          ref={this.inputs.od_1}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.od_2}
                          id="od_2"
                          placeholder="Outside diameter 2"
                          onInputChange={(e) => this.onSearchInputChange("od_2", e)}
                          onChange={(e) => this.onSearchChange("od_2", true, e)}
                          onFocus={() => this.onInputFocus("od_2")}
                          onBlur={() => this.onInputFocusLost("od_2")}
                          ref={this.inputs.od_2}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.wt_1}
                          id="wt_1"
                          placeholder="Wall thickness 1"
                          onInputChange={(e) => this.onSearchInputChange("wt_1", e)}
                          onChange={(e) => this.onSearchChange("wt_1", true, e)}
                          onFocus={() => this.onInputFocus("wt_1")}
                          onBlur={() => this.onInputFocusLost("wt_1")}
                          ref={this.inputs.wt_1}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.wt_2}
                          id="wt_2"
                          placeholder="Wall thickness 2"
                          onInputChange={(e) => this.onSearchInputChange("wt_2", e)}
                          onChange={(e) => this.onSearchChange("wt_2", true, e)}
                          onFocus={() => this.onInputFocus("wt_2")}
                          onBlur={() => this.onInputFocusLost("wt_2")}
                          ref={this.inputs.wt_2}
                        />
                      </FormGroup>
                    </Col>


                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.type}
                          id="type"
                          placeholder="Article type"
                          onInputChange={(e) => this.onSearchInputChange("type", e)}
                          onChange={(e) => this.onSearchChange("type", true, e)}
                          onFocus={() => this.onInputFocus("type")}
                          onBlur={() => this.onInputFocusLost("type")}
                          ref={this.inputs.type}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.grade}
                          id="grade"
                          placeholder="Grade"
                          onInputChange={(e) => this.onSearchInputChange("grade", e)}
                          onChange={(e) => this.onSearchChange("grade", true, e)}
                          onFocus={() => this.onInputFocus("grade")}
                          onBlur={() => this.onInputFocusLost("grade")}
                          ref={this.inputs.grade}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.specification}
                          id="specification"
                          placeholder="Specification"
                          onInputChange={(e) => this.onSearchInputChange("specification", e)}
                          onChange={(e) => this.onSearchChange("specification", true, e)}
                          onFocus={() => this.onInputFocus("specification")}
                          onBlur={() => this.onInputFocusLost("specification")}
                          ref={this.inputs.specification}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.ends}
                          id="ends"
                          placeholder="Ends"
                          onInputChange={(e) => this.onSearchInputChange("ends", e)}
                          onChange={(e) => this.onSearchChange("ends", true, e)}
                          onFocus={() => this.onInputFocus("ends")}
                          onBlur={() => this.onInputFocusLost("affiliate")}
                          ref={this.inputs.ends}
                        />
                      </FormGroup>
                    </Col>


                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.surface}
                          id="surface"
                          placeholder="Surface treatments"
                          onInputChange={(e) => this.onSearchInputChange("surface", e)}
                          onChange={(e) => this.onSearchChange("surface", true, e)}
                          onFocus={() => this.onInputFocus("surface")}
                          onBlur={() => this.onInputFocusLost("surface")}
                          ref={this.inputs.surface}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true} options={this.state.other}
                          id="other"
                          placeholder="Other"
                          onInputChange={(e) => this.onSearchInputChange("other", e)}
                          onChange={(e) => this.onSearchChange("other", true, e)}
                          onFocus={() => this.onInputFocus("other")}
                          onBlur={() => this.onInputFocusLost("other")}
                          ref={this.inputs.other}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.certificate}
                          id="certificate"
                          placeholder="Certificates"
                          onInputChange={(e) => this.onSearchInputChange("certificate", e)}
                          onChange={(e) => this.onSearchChange("certificate", true, e)}
                          onFocus={() => this.onInputFocus("certificate")}
                          onBlur={() => this.onInputFocusLost("certificate")}
                          ref={this.inputs.certificate}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <SelectInput
                          creatable={true}
                          options={this.state.heat_treatment}
                          id="heat_treatment"
                          placeholder="Heat treatment"
                          onInputChange={(e) => this.onSearchInputChange("heat_treatment", e)}
                          onChange={(e) => this.onSearchChange("heat_treatment", true, e)}
                          onFocus={() => this.onInputFocus("heat_treatment")}
                          onBlur={() => this.onInputFocusLost("heat_treatment")}
                          ref={this.inputs.heat_treatment}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Collapse>
              </Col>

              <Col md="3">
                <div id="available_stock_field">
                  <label htmlFor={"available_stock"}>{"ATP > 0"}</label>
                  <FormGroup id="available_stock_form_group">
                    <CheckboxInput
                      creatable={true}
                      id="available_stock"
                      ref={this.inputs.available_stock}
                      className="d-block mr-auto ml-0"
                    />
                  </FormGroup>
                </div>
              </Col>

              <br />
              <br />
              <Col md="12" sm="12">
                <Button type="submit" outline color="primary" className="d-block ml-auto mr-0">Submit search</Button>
              </Col>
            </Row>
          </Container>

        </Form>
        <div className="element-box">
          <Collapse isOpen={this.state.selectedList.length > 0}>
            <Row>
              <Col md="12"><div className="d-block">
                <ExportExcel exportList={this.state.selectedList} />
                <Button color="export-button" className="p-0 fs-09" onClick={this.clearSelections}><FontAwesomeIcon icon={faSquare} /> Clear</Button></div></Col>
            </Row>
          </Collapse>

          <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
            provider: this.dataProvider,
            loader: [13, 15],
            searchCallback: this.searchCallback,
            columns: articleColumns,
            paging: paging
          })} />

        </div>

        {affiliate}
        {articleDetails}

      </Container>
    );
  }
}

export default AffiliateStockPage;

