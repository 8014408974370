import React from 'react';
import VlbSimple from './VlbSimple';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Row, Col, Button, FormGroup, Label, Alert } from 'reactstrap';
import logo from '../../assets/img/logo.png'
import logoOn from '../../assets/img/logo-on.png'
import Identity from '../user/Identity';
import { User, IUser } from '../models/User';
import { AxiosError } from 'axios';
import { CheckboxInput } from '../../common/components/widgets/form/input/CheckboxInput';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import { Link } from 'react-router-dom';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import { ILoginText, LoginText } from '../models/LoginText';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { UploadStatus } from '../models/UploadStatus';

@withRouter(Path.LOGIN.toString(), PageContainer)
@withSecurity([Role.GUEST.toString()], Identity, Path.AFFILIATES_STOCK)
export default class LoginPage extends VlbSimple {
    private submitted: boolean = false;

    private user: User = new User(true, User.SCENARIO.LOGIN);
    private loginText: LoginText = new LoginText(true);
    private uploadStatus: UploadStatus = new UploadStatus(true);

    constructor(props) {
        super(props);

        this.readInfo = this.readInfo.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
        this.handleLogIn = this.handleLogIn.bind(this);

        this.state = {
            messages: [],
            newFiles: true
        }
    }

    private inputs: { [attr: string]: React.RefObject<any> } = {
        username: React.createRef(),
        password: React.createRef(),
        remember_me: React.createRef(),
    };

    private readInfo(): IUser {
        const plainObject: IUser = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    private handleValidate(event: any): boolean {
        this.readInfo();
        const valid = this.user.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.user.getErrors();
                this.fillInputsWithErrors(errors);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.inputs[key].current.setError(errors[key]);
            }
        }
    }

    protected handleLogIn(event: React.MouseEvent<HTMLElement>): void {
        this.submitted = true;

        const plainObject: IUser = this.readInfo();
        const valid: boolean = this.handleValidate(event);

        if (valid) {
            this.user.login(plainObject).then((response: any) => {

                document.location.pathname = Path.AFFILIATES_STOCK.toString();

            }).catch((error: AxiosError) => {
                const aError: AxiosError = error;

                if (aError.response && aError.response.data.errorCode) {
                    this.fillInputsWithErrors({
                        password: "Username and password do not match"
                    });
                }
            });
        }

        event.preventDefault();
    }

    componentDidMount() {

        let params: IRestDataSourceParams = {
            filters: [{
                attr: "status_id",
                operator: "eq",
                val: 1
            }]
        }

        this.loginText.getListPlain(params).then(response => {
            console.log(response)

            this.setState({
                messages: response.data
            })
        })

        this.uploadStatus.getListPlain({}).then(response => {
            console.log(response)

            this.setState({
                newFiles: response.data.length > 0
            })
        })
    }

    pageTitle() {
        return "Login" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
        ];
    }

    renderContent() {


        let messages = (this.state.messages.length > 0) ? this.state.messages.map(message =>
            (
                <Alert color={message.level} className="text-break">
                    {message.content}
                </Alert>
            )
        ) : null;

        return (
            <div className="login-page">
                <Row>
                    <Col xl="3" className="login-content">
                        <div className="content d-flex align-items-center">
                            <Row>
                                <Col xs="12">
                                    <div className="logo-content mb-3"><img alt="logo" src={logo} /></div>
                                </Col>
                                <Col xs="12">
                                    <div className="text-content">
                                        Van Leeuwen is a globally operating trading company specialized in steel pipes, and pipe and tube applications. We are active in virtually all industrial sectors. With some forty branches spread throughout Europe, the Middle East, Asia, Australia and America, we ensure that materials are available anywhere in the world, customized wherever needed. Our employees have specialist knowledge of sourcing, project management, logistics and stock planning and work closely together with our customers in the Industry and Energy markets. The combination of global logistics and knowledge of products and customer applications makes Van Leeuwen a leading company in its markets.
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl="9" className="login-form">
                        <div className="form d-flex justify-content-center align-items-center">

                            <Row>
                                <Col xs="12">
                                    <div className="logo-form mb-3"><img alt="logo" src={logoOn} /></div>
                                </Col>
                                <Col xs="12">
                                    <form>
                                        <div className="mb-3">
                                            {messages}
                                            {
                                                !this.state.newFiles ?
                                                    (<Alert color="danger" className="text-break">
                                                        No actual stock data uploaded today, please contact the IT Helpdesk.
                                                    </Alert>) :
                                                    null
                                            }
                                        </div>
                                        <FormGroup>
                                            <TextInput tabIndex={1} label="Username" id="username" ref={this.inputs.username} onBlur={this.handleValidate.bind(this)} autoComplete="username" placeholder="Enter your username" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label tabIndex={5} className="forgotpassword" for="Forgot Password"><Link to={Path.FORGOTTEN_PASSWORD}>Forgot password?</Link></Label>
                                            <PasswordInput tabIndex={2} label="Password" id="password" ref={this.inputs.password} autoComplete="current-password" placeholder="Enter your password" />
                                        </FormGroup>
                                        <FormGroup>
                                            <CheckboxInput tabIndex={3} id="check" label="Remember me" ref={this.inputs.remember_me} />
                                        </FormGroup>
                                        <Button tabIndex={4} color="primary" type="submit" className="mt-3" onClick={this.handleLogIn.bind(this)}>Submit</Button>
                                    </form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
