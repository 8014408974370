import {IValidator} from "./DataModelValidator";
import {ValidatorError} from "./errors/ValidatorError";

export interface IMatchValidatorParams {
    toBeMatchedWithCallback: (data: any) => any,
    attrName: string
}

export class MatchValidator implements IValidator {

    private toBeMatchedWithCallback:(data:any) => any;
    private attrName:string;

    public constructor(params: IMatchValidatorParams) {
        this.toBeMatchedWithCallback = params.toBeMatchedWithCallback;
        this.attrName = params.attrName;
    }

    public validate(val: any, formData: any): ValidatorError | boolean {
        const toBeMatchedWith:any = this.toBeMatchedWithCallback(formData);

        return (val === toBeMatchedWith)?true:ValidatorError.VALUE_DOES_NOT_MATCH;
    }

    public getAdditionalParams(): any {
        return {
            attrName: this.attrName
        }
    }
}