import React from "react";
import {PageType} from "../pages/Page";
import {DataModel} from "../../model/DataModel";
import {IRoute} from "../routing/Router";

/**
 * Container Singleton.
 *
 * Used as place to register all needed components so decorators can be executed on them.
 * Collecting of configurations for different components is also done through this container.
 */

/**
 * Array of pages registered in container.
 *
 * @type {Array}
 */
const pages: PageType[] = [];

/**
 * Array of routes registered in container.
 *
 * @type {Array}
 */
const routes: IRoute[] = [];

const groupedRoutes: {[key: string]: IRoute[]} = {};

/**
 * Array of data models registered in container.
 *
 * @type {Array}
 */
const models: DataModel<any>[] = [];

/**
 * Component rendered for 404 page
 */
let notFound: React.ComponentType = null;

export function set404(comp: React.ComponentType) {
    notFound = comp;
}

export function get404(): React.ComponentType {
    return notFound;
}

/**
 * Operation for adding / registering page in container.
 *
 * @param page
 */
export function addPage(page: PageType) {
    pages.push(page);
}

/**
 * Retrieve all registered pages.
 *
 * @return {PageType[]}
 */
export function getPages(): PageType[] {
    return pages;
}

/**
 * Operation for adding / registering route in container.
 */
export function addRoute(path: string, comp: React.ComponentType, isExact: boolean, group?: string) {

    if (group) {
        if (!groupedRoutes[group]) {
            groupedRoutes[group] = [];
        }

        groupedRoutes[group].push({
            path: path,
            comp: comp,
            isExact: isExact
        });
    } else {
        routes.push({
            path: path,
            comp: comp,
            isExact: isExact
        });
    }
}

/**
 * Retrieve all registered routes.
 *
 * @return {Array<{path: string, comp: React.ComponentType}>}
 */
export function getRoutes(): IRoute[] {
    return routes;
}

export function getGroupedRoutes(): {[key: string]: IRoute[]} {
    return groupedRoutes;
}

/**
 * Operation for adding / registering data model in container.
 *
 * @param model
 */
export function addModel<T>(model: DataModel<T>) {
    models.push(model);
}

/**
 * Retrieve all registered models.
 *
 * @return {DataModel[]}
 */
export function getModels<T>(): DataModel<T>[] {
    return models;
}

