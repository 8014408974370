import {ActionCreator} from "redux";
import {NEW_ITEM, UPDATE_STATE, DESTROY_STATE} from "./ActionTypes";

export function newItem<T> () : ActionCreator<NEW_ITEM<T>> {
    return (key: string, storeKey: string, data: T) => {
        return {
            type: "@@DataModel/NEW_ITEM",
            key: key,
            storeKey: storeKey,
            payload: data
        };
    };
}

export function updateState<T> () : ActionCreator<UPDATE_STATE<T>> {
    return (key: string, storeKey: string, data: T) => {
        return {
            type: "@@DataModel/UPDATE_STATE",
            key: key,
            storeKey: storeKey,
            payload: data
        };
    };
}

export function destroyState<T> () : ActionCreator<DESTROY_STATE<T>> {
    return (key: string, storeKey: string, data: T) => {
        return {
            type: "@@DataModel/DESTROY_STATE",
            key: key,
            storeKey: storeKey
        };
    };
}