import { default as Axios, AxiosResponse, AxiosError } from "axios";
import { DataSource } from "./DataSource";

/**
 * Data source implementation for REST data source.
 */
export class RestDataSource<S> extends DataSource<S> {

    private restBasePath: string;
    private headers: { [key: string]: string };

    private handleNonReachableServer(promise: Promise<AxiosResponse>): void {
        promise.catch((error: AxiosError) => {
            if (typeof error.response === "undefined") {
                alert("Server not responding! You will be redirected to homepage in 5 seconds.");
                setTimeout(() => {
                    window.location.pathname = "/";
                }, 5000);
            }
        });
    }

    /**
     * @constructor
     */
    public constructor(restBasePath: string, headers: { [key: string]: string }) {
        super();
        this.restBasePath = restBasePath;
        this.headers = headers;
    }

    public getListPlain(data?: any): Promise<AxiosResponse> {
        let queryParams: string = "";
        if (data) {
            queryParams = this.prepareGetQueryParams(data);
        }

        const promise: Promise<AxiosResponse> = Axios.get<S[]>(this.restBasePath + queryParams, {
            headers: this.headers
        });

        this.handleNonReachableServer(promise);

        return promise;
    }

    /**
     * @inheritDoc
     */
    public getList(data?: S): Promise<S[] | AxiosError> {
        return this.getListPlain(data).then((response: AxiosResponse): S[] => {
            return response.data;
        }).catch((error: AxiosError): AxiosError => {
            return error;
        });
    }

    public addCustomPath(customPath: string) {
        return new RestDataSource<S>(this.restBasePath + customPath, this.headers);
    }

    public getOperation(data: S): Promise<S | AxiosError> {
        return this.plainGetOperation(data).then((response: AxiosResponse): S => {
            return response.data;
        }).catch((error: AxiosError): AxiosError => {
            return error;
        });
    }

    public postOperation(data: S): Promise<S | AxiosError> {
        return this.plainPostOperation(data).then((response: AxiosResponse): S => {
            return response.data;
        }).catch((error: AxiosError): AxiosError => {
            return error;
        });
    }

    public plainGetOperation(data: S, additionalParams: { [key: string]: any } = {}): Promise<AxiosResponse> {
        const queryParams: string = this.prepareGetQueryParams(data);

        const promise: Promise<AxiosResponse> = Axios.get<S>(this.restBasePath + queryParams, {
            headers: this.headers,
            ...additionalParams
        });

        this.handleNonReachableServer(promise);

        return promise;
    }

    public plainPostOperation(data: S): Promise<AxiosResponse> {
        const promise: Promise<AxiosResponse> = Axios.post<S>(this.restBasePath, data, {
            headers: this.headers
        });

        this.handleNonReachableServer(promise);

        return promise;
    }

    public plainDelOperation(): Promise<void> {
        const promise: Promise<AxiosResponse> = Axios.delete(this.restBasePath, {
            headers: this.headers
        });

        this.handleNonReachableServer(promise);

        return promise.then(() => { });
    }

    public plainPatchOperation(data: S): Promise<AxiosResponse> {
        const promise: Promise<AxiosResponse> = Axios.patch<S>(this.restBasePath, data, {
            headers: this.headers
        });

        this.handleNonReachableServer(promise);

        return promise;
    }

    public plainPutOperation(data: S): Promise<AxiosResponse> {
        const promise: Promise<AxiosResponse> = Axios.put<S>(this.restBasePath, data, {
            headers: this.headers
        });

        this.handleNonReachableServer(promise);

        return promise;
    }

    private prepareGetQueryParams(data: S): string {
        const queryParams = [];

        for (const key in data) {
            if (data.hasOwnProperty(key)) {

                if (Array.isArray(data[key])) {
                    for (var value in data[key]) {
                        queryParams.push(key + "=" + data[key][value]);
                    }
                }

                else queryParams.push(key + "=" + data[key]);
            }
        }

        return queryParams.length > 0 ? "?" + queryParams.join('&') : "";
    }
}
