import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IAffiliate } from "./Affiliate";

export interface IUploadStatus {
    id?: number,
    affiliate_id?: number,
    affiliate?: IAffiliate,
    last_upload_date?: string,
    uploaded_article_count?: number,
    error_count?: number,
    error_log?: string
}


export class UploadStatus extends DataModel<IUploadStatus> {
    public resourceName = "upload-status";
    public resourceNamePlural = "upload-statuses";

    private _id: number;
    private _affiliate_id: number;
    private _affiliate: IAffiliate;
    private _last_upload_date: string;
    private _uploaded_article_count: number;
    private _error_count: number;
    private _error_log: string;


    protected getDefaultValues(): IUploadStatus {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IUploadStatus> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IUploadStatus> {
        return new DataModelValidator<IUploadStatus>({
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IUploadStatus>, action: AnyAction): IDataModelState<IUploadStatus> {
        return state;
    }

    public loadUploadStatuss(): Promise<IUploadStatus[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IUploadStatus | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    getStoreKey(): string {
        return "UPLOAD-STATUS";
    }

    protected setFromObj(data: IUploadStatus): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._affiliate_id = DataModel.safeGet(data.affiliate_id, this._affiliate_id) as number;
        this._affiliate = DataModel.safeGet(data.affiliate, this._affiliate);
        this._last_upload_date = DataModel.safeGet(data.last_upload_date, this._last_upload_date);
        this._uploaded_article_count = DataModel.safeGet(data.uploaded_article_count, this._uploaded_article_count);
        this._error_count = DataModel.safeGet(data.error_count, this._error_count);
        this._error_log = DataModel.safeGet(data.error_log, this._error_log);
    }


    protected toObj(): IUploadStatus {
        return {
            id: this._id,
            affiliate_id: Number(this._affiliate_id),
            affiliate: this._affiliate,
            last_upload_date: this._last_upload_date,
            uploaded_article_count: this._uploaded_article_count,
            error_count: this._error_count,
            error_log: this._error_log
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    set affiliate_id(id: number) {
        this._affiliate_id = id;
    }

    get affiliate_id() {
        return this._affiliate_id;
    }

    get affiliate() {
        return this._affiliate;
    }

    get last_upload_date() {
        return this._last_upload_date;
    }

    get uploaded_article_count() {
        return this._uploaded_article_count;
    }

    get error_count() {
        return this._error_count;
    }

    get error_log() {
        return this._error_log;
    }

    


}
