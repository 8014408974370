import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { IActionColumn, IActionColumnPathItem, ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import Identity from '../user/Identity';
import { Role } from '../user/Role';

export interface ICustomIActionColumn extends IActionColumn {
    actionRestriction: boolean;
}

export interface ICustomActionColumnPathItem extends IActionColumnPathItem {
    actionRestriction: boolean;
}

export class CustomActionColumn extends ActionColumn implements ICustomIActionColumn {
    actionRestriction: boolean;

    public constructor(editPath: string, deleteAction: ((row: Object) => void) | null = null,
        editAction: ((row: Object) => void) | null = null,
        width: number = 0,
        actionRestriction: boolean = false,
        pathItems: ICustomActionColumnPathItem[] | null = null,
        label: string = "Actions") {
        super(editPath, deleteAction, editAction, width, pathItems, label)
        this.label = label;
        this.viewPath = editPath;
        this.deleteAction = deleteAction;
        this.editAction = editAction;
        this.pathItems = pathItems;
        this.width = width;
        this.actionRestriction = actionRestriction;
    }

    public get content(): (row: any) => React.ReactNode {
        let deleteActionNode: React.ReactNode | null = null;
        let editActionNode: React.ReactNode | null = null;
        let pathItemsNode: React.ReactNode[] = [];
        let viewPathItemNode: React.ReactNode | null = null;

        return (row: any) => {

            if (this.deleteAction !== null) {
                if (this.actionRestriction) {
                    if (Identity.affiliate_id == row.affiliate_id || row.item_name === Role.ADMIN) {
                        deleteActionNode = (
                            <Link to={window.location} onClick={this.performDelete.bind(this, row)} className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></Link>
                        );
                    }
                } else if (!this.actionRestriction) {
                    deleteActionNode = (
                        <Link to={window.location} onClick={this.performDelete.bind(this, row)} className="table-edit delete"><FontAwesomeIcon icon={faTrash} /></Link>
                    );
                }
            }


            if (this.editAction !== null) {
                if (this.actionRestriction) {
                    if (Identity.affiliate_id == row.affiliate_id || row.item_name === Role.ADMIN) {
                        editActionNode = (
                            <Link to={window.location} onClick={this.performEdit.bind(this, row)} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link>
                        );
                    }
                } else if (!this.actionRestriction) {
                    editActionNode = (
                        <Link to={window.location} onClick={this.performEdit.bind(this, row)} className="table-edit edit"><FontAwesomeIcon icon={faEdit} /></Link>
                    );
                }
            }

            if (this.pathItems) {
                this.pathItems.forEach((pathItem: ICustomActionColumnPathItem) => {

                    const cssProps: React.CSSProperties = (pathItem.iconStyle) ? pathItem.iconStyle : {};

                    pathItemsNode.push(<a key={row.id} href={'#'} onClick={
                        this.performCustomOperation.bind(this, row.id, pathItem.operationCb, pathItem.message)}
                        className={pathItem.iconClassName}
                        style={{
                            paddingLeft: '20px', color: 'blue', cursor: 'pointer',
                            ...cssProps
                        }} />);
                });
            }

            if (this.viewPath) {
                viewPathItemNode = (
                    <Link to={this.viewPath + row.id} className="table-edit view"><FontAwesomeIcon icon={faEye} /></Link>
                )
            }

            return (<div>
                {viewPathItemNode}
                {editActionNode}
                {deleteActionNode}
                {pathItemsNode}
            </div>);
        }
    }
}
