import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IAffiliate } from "./Affiliate";

export interface ISearch {
    id?: number,
    result?: any,
    affiliate_id?: string,
    affiliate?: IAffiliate,
    available_stock?: number,
    s?: string,
    od_1?: string,
    od_2?: string,
    wt_1?: string,
    wt_2?: string,
    type?: string,
    specification?: string,
    grade?: string,
    heat_treatment?: string,
    ends?: string,
    surface?: string,
    certificate?: string,
    other?: string,
    size_short?: string,
    description?: string
    code?: string
}



export class Search extends DataModel<ISearch> {

    public resourceName = "search";

    private _id?: number;
    private _affiliate_id: string;
    private _affiliate: IAffiliate;
    private _available_stock: number;
    private _code: string;
    private _od_1: string;
    private _od_2: string;
    private _wt_1: string;
    private _wt_2: string;
    private _type: string;
    private _specification: string;
    private _grade: string;
    private _heat_treatment: string;
    private _ends: string;
    private _surface: string;
    private _certificate: string;
    private _other: string;
    private _size_short: string;
    private _description: string;
    private _result: any;


    protected getDefaultValues(): ISearch {
        return { result: [] };
    }


    public createDataSource(resourceName: string = this.resourceName): RestDataSource<ISearch> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISearch> {
        return new DataModelValidator<ISearch>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISearch>, action: AnyAction): IDataModelState<ISearch> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getResultListWithFilters(params: string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath(params)
            .getListPlain(RestParamsQueryBuilder.buildRestParams({}))
    }

    public loadById(id: number): Promise<ISearch | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public search(data: ISearch, params: IRestDataSourceParams): Promise<AxiosResponse> {

        let query: ISearch = {
        };

        if (data.size_short != null) {
            if (data.size_short.includes(",")) {
                let commaIndex = data.size_short.indexOf(",");
                data.size_short = data.size_short.substring(0, commaIndex) + data.size_short.substring(commaIndex, data.size_short.length);
            }
            query.size_short = data.size_short
        }

        if (data.description != null && data.description != 'undefined' && data.description != '') { query.description = data.description }
        if (data.code) { query.code = data.code }
        if (data.affiliate != null) { query.affiliate = data.affiliate }
        if (data.affiliate_id != null) { query.affiliate_id = data.affiliate_id }
        if (data.available_stock != null) { query.available_stock = data.available_stock}
        if (data.od_1) { query.od_1 = data.od_1 }
        if (data.od_2) { query.od_2 = data.od_2 }
        if (data.wt_1) { query.wt_1 = data.wt_1 }
        if (data.wt_2) { query.wt_2 = data.wt_2 }
        if (data.type != null) { query.type = data.type }
        if (data.grade != null) { query.grade = data.grade }
        if (data.specification != null) { query.specification = data.specification }
        if (data.ends != null) { query.ends = data.ends }
        if (data.surface != null) { query.surface = data.surface }
        if (data.other != null) { query.other = data.other }
        if (data.certificate != null) { query.certificate = data.certificate }
        if (data.heat_treatment != null) { query.heat_treatment = data.heat_treatment }


        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + 'search', UserIdentity.getRequestHeaders())
            .plainGetOperation({ ...query, ...RestParamsQueryBuilder.buildRestParams(params) });
    }

    public autocomplete(attr: string, value: string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + '/autocomplete?attr=' + attr + '&val=' + value)
            .getListPlain({})
    }


    public static toURLParams(obj: Object): string {
        return Object.keys(obj).map(function (key) {
            return key + '=' + obj[key];
        }).join('&');
    }


    getStoreKey(): string {
        return "SEARCH";
    }

    protected setFromObj(data: ISearch): void {
        this._size_short = DataModel.safeGet(data.size_short, this._size_short);
        this._affiliate_id = DataModel.safeGet(data.affiliate_id, this._affiliate_id);
        this._affiliate = DataModel.safeGet(data.affiliate, this._affiliate);
        this._available_stock = DataModel.safeGet(data.available_stock, this._available_stock);
        this._od_1 = DataModel.safeGet(data.od_1, this._od_1);
        this._od_2 = DataModel.safeGet(data.od_2, this._od_2);
        this._wt_1 = DataModel.safeGet(data.wt_1, this._wt_1);
        this._wt_2 = DataModel.safeGet(data.wt_2, this._wt_2);
        this._type = DataModel.safeGet(data.type, this._type);
        this._specification = DataModel.safeGet(data.specification, this._specification);
        this._grade = DataModel.safeGet(data.grade, this._grade);
        this._heat_treatment = DataModel.safeGet(data.heat_treatment, this._heat_treatment);
        this._ends = DataModel.safeGet(data.ends, this._ends);
        this._surface = DataModel.safeGet(data.surface, this._surface);
        this._certificate = DataModel.safeGet(data.certificate, this._certificate);
        this._other = DataModel.safeGet(data.other, this._other);
        this._description = DataModel.safeGet(data.description, this._description);
        this._code = DataModel.safeGet(data.code, this._code);
    }


    protected toObj(): ISearch {
        return {
            size_short: this._size_short,
            affiliate_id: this._affiliate_id,
            affiliate: this._affiliate,
            available_stock: this.available_stock,
            od_1: this._od_1,
            od_2: this._od_2,
            wt_1: this._wt_1,
            wt_2: this._wt_2,
            type: this._type,
            specification: this._specification,
            grade: this._grade,
            heat_treatment: this._heat_treatment,
            ends: this._ends,
            surface: this._surface,
            certificate: this._certificate,
            other: this._other,
            description: this._description,
            code: this._code
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get result() {
        return this._result;
    }

    set result(result: any) {
        this._result = result;
    }

    set affiliate_id(id: string) {
        this._affiliate_id = id;
    }

    get affiliate_id() {
        return this._affiliate_id;
    }

    get available_stock() {
        return this._available_stock;
    }

    get od_1() {
        return this._od_1;
    }
    get od_2() {
        return this._od_2;
    }

    get wt_1() {
        return this._wt_1;
    }
    get wt_2() {
        return this._wt_2;
    }
    get type() {
        return this._type;
    }
    get specification() {
        return this._specification;
    }
    get grade() {
        return this._grade;
    }
    get heat_treatment() {
        return this._heat_treatment;
    }
    get ends() {
        return this._ends;
    }
    get surface() {
        return this._surface;
    }
    get certificate() {
        return this._certificate;
    }
    get other() {
        return this._other;
    }
    get size_short() {
        return this._size_short;
    }
    get description() {
        return this._description;
    }
    get code() {
        return this._code;
    }

}
