import React from 'react';
import { ILayout } from "../../common/components/layouts/ILayout";
import Header from "./Header";
import Footer from "./Footer";

export default class MainLayout implements ILayout {

    public renderAroundContent(content: React.ReactNode): React.ReactNode {

        return (
            <div className="full-page">
                <Header />
                    {content}
                <Footer />
            </div>
        );

    }
}