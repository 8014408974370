import * as React from 'react';
import { IRestFilterParam } from "../../../../dataSource/IRestDataSourceParams";
import { TextInput } from "../../form/input/TextInput";
import { Col, FormGroup } from 'reactstrap';

export class BasicFilter {

    private _placeholder?: string;

    private _colSize?: number;

    private _attr?: string;

    private _filterParam?: IRestFilterParam;

    private _isNumber: boolean;

    protected _filterInput: React.RefObject<any> = React.createRef();

    private reloadData: () => void = () => {
    };

    public constructor(isNumber: boolean = false, placeholder: string = "", colSize: number = null) {
        this._isNumber = isNumber;
        this._placeholder = placeholder;
        this._colSize = colSize;
    }

    public extendFilterParams(filterParams: IRestFilterParam[] | undefined): IRestFilterParam[] | undefined {
        let filterParamIndex: number | null = null;

        if (this._filterParam && this._filterParam.val) {
            if (filterParams) {
                filterParams.forEach((filterParam: IRestFilterParam, index: number) => {
                    if (filterParam.attr === this._attr) {
                        filterParamIndex = index;
                    }
                });
            } else {
                filterParams = [];
            }

            if (filterParamIndex === null) {
                filterParams.push(this._filterParam);
            } else {
                filterParams[filterParamIndex] = this._filterParam;
            }
        }

        return filterParams;
    }

    public addReload(cb: () => void): void {
        this.reloadData = cb;
    }

    private isFilterChanged(currentFilterInputVal: string) {
        if (this._isNumber) {
            return this._filterParam && currentFilterInputVal !== this._filterParam.val;
        } else {
            return this._filterParam && (
                encodeURI("%") + currentFilterInputVal + encodeURI("%") !== this._filterParam.val);
        }
    }

    protected handleFilter(event: KeyboardEvent): void {
        const currentFilterInputVal: string = this._filterInput.current.inputRef.current.value;

        if ((!this._filterParam && currentFilterInputVal) ||
            (this.isFilterChanged(currentFilterInputVal))) {

            if (this._isNumber) {
                this._filterParam = {
                    attr: this._attr ? this._attr : '',
                    val: currentFilterInputVal,
                    operator: 'eq'
                };
            } else {
                this._filterParam = {
                    attr: this._attr ? this._attr : '',
                    val: encodeURI("%") + currentFilterInputVal + encodeURI("%"),
                    operator: 'ILIKE'
                };
            }


            this.reloadData();
        }

        event.preventDefault();
    }

    public render(): React.ReactNode {
        let isCol;
        if (this._colSize) {
            isCol = (
                <Col lg={this._colSize} >
                    <FormGroup>
                        <TextInput filterMode={true} label="" onChange={this.handleFilter.bind(this)} ref={this._filterInput} placeholder={this._placeholder} />
                    </FormGroup>
                </Col>
            )
        } else {
            isCol = (
                <TextInput filterMode={true} label="" onChange={this.handleFilter.bind(this)} ref={this._filterInput} placeholder={this._placeholder} />
            )
        }
        return (
            <>
                {isCol}
            </>
        );
    }

    set attr(value: string | undefined) {
        this._attr = value;
    }
}