import * as React from 'react';
import {BasicFilter} from "./BasicFilter";
import {DropdownInput, IDropdownListItem} from "../../form/input/DropdownInput";
import { FormGroup, Col } from 'reactstrap';


export class DropdownFilter extends BasicFilter {
    private dropdownListItems: IDropdownListItem[] = [];
    private _placeholder_dropdown?: string;
    private _colSized_dropdown?: number;

    public constructor(listData: { [key in string | number]: string }, placeholder: string = "", colSize: number = null) {
        super(true);
        this._placeholder_dropdown = placeholder;
        this._colSized_dropdown = colSize;

        for (let key in listData) {
            if (listData.hasOwnProperty(key)) {
                this.dropdownListItems.push({
                    id: key,
                    name: listData[key]
                });
            }
        }

        this.dropdownListItems.sort((a: IDropdownListItem, b: IDropdownListItem) => {
            return (a.name < b.name)?-1:((a.name === b.name)?0:1);
        });
    }

    public render(): React.ReactNode {
        let isCol;
        if (this._colSized_dropdown) {
            isCol = (
                <Col lg={this._colSized_dropdown} >
                    <FormGroup>
                        <DropdownInput filterMode={true} label="" listItems={this.dropdownListItems} placeholder={this._placeholder_dropdown} onChange={this.handleFilter.bind(this)} ref={this._filterInput}/>
                    </FormGroup>
                </Col>
            )
        } else {
            isCol = (
                <DropdownInput filterMode={true} label="" listItems={this.dropdownListItems} onChange={this.handleFilter.bind(this)} ref={this._filterInput}/>
            )
        }
        return (
            <>
                {isCol}
            </>
        );
    }
}