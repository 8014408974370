import React, { Component } from 'react';
import Router from './common/components/routing/Router';
import { Container } from "./index";
import { StoreProvider } from './common/model/StoreProvider';

class App extends Component {
  render() {
    return (
      <StoreProvider container={Container}>
        <Router container={Container} />
      </StoreProvider>
    );
  }
}

export default App;
