import * as React from 'react';
import { FormInput, IFormInputProps } from "../FormInput";

export interface IDropdownListItem {
    id: number | string,
    name: string
}

export interface IDropdownInputProps extends IFormInputProps {
    label?: string,
    placeholder?: string,
    error?: string,
    listItems?: IDropdownListItem[],
    noEmptyOption?: boolean,
    id?: string,
}

export class DropdownInput<T extends IDropdownInputProps = IDropdownInputProps> extends FormInput<T> {
    protected renderLabel(): React.ReactNode {
        return this.props.label ? (<label htmlFor={this.props.id} className={"form-control-label " + (this.getErrorClass())}>{this.props.label}</label>)
            : null;
    }

    protected renderInput(): React.ReactNode {
        const additionalProps: any = {};

        if (this.props.id) {
            additionalProps['htmlFor'] = this.props.id;
        }

        const options: React.ReactNode[] = (this.props.noEmptyOption) ? [] : [
            <option key="listItem-empty" value="">{this.props.placeholder}</option>
        ];

        let listItems: IDropdownListItem[] | undefined = [];

        if (this.props.listItems && this.props.listItems.length) {
            listItems = this.props.listItems;
        }

        if (listItems) {
            listItems.forEach((listItem: IDropdownListItem, index: number) => {
                options.push(<option key={'listItem' + index} value={listItem.id}>{listItem.name}</option>);
            });
        }

        return (<select ref={this.inputRef}
            id={this.props.id}
            value={this.props.initialValue}
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            onBlur={(this.props.onBlur) ? this.props.onBlur : () => {
            }}
            onChange={(this.props.onChange) ? this.props.onChange : () => {
            }}
            className={"form-control " + this.getInputErrorClass()}>
            {options}
        </select>);
    }

    protected renderError(): React.ReactNode {
        return (<div className={this.getErrorTextErrorClass()}>{(this.error !== null) ? this.error : ""}</div>);
    }

    public getValue(): any {
        // pošalji null vrijednost postoji i ako je prazan string
        /*
        razlog zašto null nije default vrijednost je čudno ponašanje 
        selecta - doda praznu opciju i opciju sa placeholderom posebno
        */
        if (this.inputRef.current) {
            return this.inputRef.current.value == "" ? null : this.inputRef.current.value
        }

        return "";
    }

    public getReadOnly(val: any): any {
        const listItems: IDropdownListItem[] | undefined = this.props.listItems ? this.props.listItems : [];

        if (listItems) {
            if (listItems.length) {
                let result: any = '';

                listItems.forEach((listItem: IDropdownListItem) => {
                    if (listItem.id == val) {
                        result = listItem.name;
                    }
                });

                return result;
            }
        }

        return "";
    }
}
