import * as React from 'react';
import {IRestSortParam} from "../../../../dataSource/IRestDataSourceParams";

export class BasicSorter {
    private _attr?: string;

    private _direction: number = 0; // 0-none, 1-asc and 2-desc

    private reloadData: () => void = () => {
    };

    public extendSortParams(sortParam: IRestSortParam | undefined): IRestSortParam | undefined {
        if (this._direction && this._attr) {
            sortParam = {
                attr: this._attr,
                ascending: this._direction === 1
            }
        }

        return sortParam;
    }

    public reset(): void {
        this._direction = 0;
    }

    public addReload(cb: () => void): void {
        this.reloadData = cb;
    }

    public sort(event: any): void {
        this._direction = (++this._direction % 3);
        this.reloadData();

        event.preventDefault();
    }

    public render() {
        const className: string = (this._direction) ? (this._direction === 1 ? "fa fa-sort-up" : "fa fa-sort-down") : "";

        return (<i className={className}/>);
    }

    set attr(attr: string) {
        this._attr = attr;
    }
}