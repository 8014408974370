import {IValidator} from "./DataModelValidator";
import {ValidatorError} from "./errors/ValidatorError";
import {RequiredValidator} from "./RequiredValidator";

export interface ILengthValidatorParams {
    min?: number | null,
    max?: number | null
}

export class LengthValidator implements IValidator {

    private min: number | null;
    private max: number | null;

    public constructor(params: ILengthValidatorParams) {
        this.min = params.min ? params.min : null;
        this.max = params.max ? params.max : null;
    }

    public validate(val: string): ValidatorError | boolean {
        let result: ValidatorError | boolean = true;

        if(val)val = val.trim()        

        const requiredValidator:RequiredValidator = new RequiredValidator();
        const valExists: boolean = (requiredValidator.validate(val) === true);
        if (!valExists) {
            return true;
        }

        if (this.min && this.max) {
            result = (this.min <= val.length && val.length <= this.max) ? true : ValidatorError.LENGTH_NOT_IN_BETWEEN;
        } else if (this.min) {
            result = (this.min <= val.length) ? true : ValidatorError.LENGTH_IS_BELLOW_ALLOWED;
        } else if (this.max) {
            result = (val.length <= this.max) ? true : ValidatorError.LENGTH_IS_ABOVE_ALLOWED;
        }

        return result;
    }

    public getAdditionalParams(): any {
        if (this.min && this.max) {
            return {
                min: this.min,
                max: this.max
            }
        } else if (this.min) {
            return {
                min: this.min
            }
        } else if (this.max) {
            return {
                max: this.max
            }
        } else {
            return {};
        }
    }
}
