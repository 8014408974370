import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Container, Row, Col, Table, Button, FormGroup } from 'reactstrap';
import Util from '../custom/Util';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import { User, IUser } from '../models/User';
import { AxiosResponse, AxiosError } from 'axios';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';

@withRouter(Path.PROFILE_SETTINGS.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString(), Role.USER.toString()], Identity, Path.AFFILIATES_STOCK)
export default class ProfileSettingsPage extends VlbPage {
    private userModel: User = new User(true, User.SCENARIO.CHANGE_PASSWORD);

    private inputs: { [key: string]: React.RefObject<any>; } = {

        password: React.createRef(),
        password_confirmed: React.createRef()

    };

    constructor(props: any) {
        super(props);

        this.getValidateHandle = this.getValidateHandle.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.readInfo = this.readInfo.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);

        this.state = {
            userInfo: {},
            userInfoLoaded: false,
            modalDelete: false,
            currentModelDelete: null
        };
    }

    pageTitle() {
        return "Profile settings" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
            this.userModel
        ];
    }

    componentDidMount() {

        this.userModel.getCurrentUser({})
            .then((response: AxiosResponse) => {

                this.setState({
                    userInfo: response.data,
                    userInfoLoaded: true
                });


            }).catch((e: AxiosError) => {
                Util.notification("error", "Profile cannot be loaded", 1500);
            });

        super.componentDidMount();
    }

    changePassword(event: React.MouseEvent<HTMLInputElement>) {

        if (this.getValidateHandle(event)) {
            this.userModel.updatePassword(this.userModel)
                .then((response: AxiosResponse) => {
                    Util.notification("success", "Your password has been changed successfully!", 10000);
                })
                .catch((error: AxiosError) => {
                    Util.notification("error", "Password change failed.", 10000);
                });
        }
    }

    getValidateHandle(event: React.MouseEvent<HTMLInputElement>) {

        this.readInfo();
        const valid = this.userModel.validate();

        if (!valid) {
            const errors: { [attr: string]: string } = this.userModel.getErrors();
            this.fillInputsWithErrors(errors, this.inputs);
        }

        event.preventDefault();

        return valid;
    }

    private readInfo(): IUser {

        const plainObject: IUser = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }

        this.userModel.setFromPlainObject(plainObject);

        return plainObject;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    renderContent() {

        let user = this.state.userInfo;
        let loadStatus = this.state.userInfoLoaded;
        const loader = <i className="content-loader w-100 mw-2" />;

        return (
            <>
                <Container fluid className="page-content" >

                    <div className="title-box">
                        <h1 className="page-title">Profile settings</h1>
                    </div>

                    <Row>
                        <Col xl="4">
                            <div className="element-box">
                                <h2>Profile info</h2>
                                <Table hover responsive>
                                    <tbody>
                                        <tr>
                                            <th>Username</th>
                                            <td>{loadStatus ? user.username : loader}</td>
                                        </tr>

                                        <tr>
                                            <th>E-mail</th>
                                            <td>{loadStatus ? user.email : loader}</td>
                                        </tr>

                                        <tr>
                                            <th>Role</th>
                                            <td>{loadStatus ? user.item_name : loader}</td>
                                        </tr>

                                        <tr>
                                            <th>Abbreviation</th>
                                            <td>{loadStatus ? user.abbreviation : loader}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </Col>

                        <Col xl="8">
                            <div className="element-box">
                                <h2>Change password</h2>
                                <p>It's always a good idea to update your password regularly and to make sure it's unique from other passwords you use. If you've forgotten your password, you can reset it for a fresh start.</p>
                                <FormGroup>
                                    <PasswordInput
                                        label=""
                                        id="Password"
                                        placeholder="New password"
                                        ref={this.inputs.password}
                                        onBlur={this.getValidateHandle}
                                        autoComplete="new-password"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <PasswordInput
                                        label=""
                                        id="rePassword"
                                        placeholder="Confirm password"
                                        ref={this.inputs.password_confirmed}
                                        onBlur={this.getValidateHandle}
                                        autoComplete="new-password"
                                    />
                                </FormGroup>
                                <Button color="primary" onClick={this.changePassword}>Change password</Button>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </>
        );
    }
}
