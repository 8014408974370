import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IUser } from "./User";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface ILoginText {
    id?: number;
    status_id?: number;
    level?: string;
    created_at?: string;
    updated_at?: string;
    created_by?: number;
    updated_by?: number;
    content?: string;
}


export class LoginText extends DataModel<ILoginText> {
    public resourceName = "login-text";
    public resourceNamePlural = "login-texts";

    private _id: number;
    private _status_id: number;
    private _level: string;
    private _created_at: string;
    private _updated_at: string;
    private _created_by: number;
    private _created_by_user: IUser;
    private _updated_by: number;
    private _updated_by_user: IUser;
    private _content: string;


    protected getDefaultValues(): ILoginText {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<ILoginText> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ILoginText> {
        return new DataModelValidator<ILoginText>({
            content: [new RequiredValidator(), new LengthValidator({ min: 10 })],
            level: [new RequiredValidator(), new LengthValidator({ max: 50 })]
        }, ErrorMessages.getDefaultErrorMessageCallbacks())
    }

    protected modelReducer(state: IDataModelState<ILoginText>, action: AnyAction): IDataModelState<ILoginText> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ILoginText | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }



    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                content: this._content,
                level: this._level
            });
    }

    public update(data: ILoginText): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                level: this._level,
                content: this._content
            });
    }

    getStoreKey(): string {
        return "LOGIN-TEXT";
    }

    protected setFromObj(data: ILoginText): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._status_id = DataModel.safeGet(data.status_id, this._status_id) as number;
        this._content = DataModel.safeGet(data.content, this._content);
        this._level = DataModel.safeGet(data.level, this._level);
        this._created_at = DataModel.safeGet(data.created_at, this._created_at);
        this._created_by = DataModel.safeGet(data.created_by, this._created_by);
        this._updated_by = DataModel.safeGet(data.updated_by, this._updated_by);
        this._updated_at = DataModel.safeGet(data.updated_at, this._updated_at);
    }


    protected toObj(): ILoginText {
        return {
            id: this._id,
            status_id: Number(this._status_id),
            content: this._content,
            level: this._level,
            created_at: this._created_at,
            created_by: this._created_by,
            updated_by: this._updated_by,
            updated_at: this._updated_at
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get content() {
        return this._content;
    }

    get created_at() {
        return this._created_at;
    }

    get created_by() {
        return this._created_by;
    }

    get updated_at() {
        return this._updated_at;
    }

    get updated_by() {
        return this._updated_by;
    }

    get level() {
        return this._level;
    }

    set level(level: string) {
        this._level = level;
    }
}
