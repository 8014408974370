import { IValidator } from "./DataModelValidator";
import { ValidatorError } from "./errors/ValidatorError";

export class SelectIdValidator implements IValidator {
    private emptyArrayAsInvalid: boolean;

    public constructor(emptyArrayAsInvalid: boolean = false) {
        this.emptyArrayAsInvalid = emptyArrayAsInvalid;
    }

    validate(val: any): ValidatorError | boolean {
        let result: boolean = true;

        if (typeof val === 'undefined' || val === null) {
            result = false;
        }
        else if (val === 0) {
            result = false;
        }
        else if (typeof val === 'string' && val === '') {
            result = false;
        } else if (this.emptyArrayAsInvalid && typeof val.length !== 'undefined' && val.length === 0) {
            result = false;
        }

        return (result !== false) ? true : ValidatorError.REQUIRED;
    }

    getAdditionalParams(): any {
        return {};
    }
}