import Noty, { Type, Theme, Layout } from "noty";
import React from 'react';
import moment from "moment";


class Util {

    static notification(
        type: Type = 'success',
        text: string = '',
        timeout: number | false,
        theme: Theme = 'bootstrap-v4',
        layout: Layout = 'topRight',
        progressBar: boolean = true,
        closeWith: ('click' | 'button')[] = ['click']
    ) {

        return new Noty({
            type,
            theme,
            layout,
            text,
            timeout,
            progressBar,
            closeWith
        }).show();

    }

    static topFunction() {
        window.scrollTo(0, 0)
    }

    static copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.classList.add('hide-textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
    }

    static tableLoader(tdNumber: number, trNumber: number) {

        let tableLoader = []

        for (var i = 1; i <= trNumber; i++) {
            if (tdNumber === 1) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-50" /></td>
                    </tr>
                )
            } else if (tdNumber === 2) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            } else if (tdNumber === 3) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            } else if (tdNumber === 4) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            } else if (tdNumber === 5) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            } else if (tdNumber === 6) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            } else if (tdNumber === 7) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            } else if (tdNumber === 8) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            }
            else if (tdNumber === 11) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            }
            else if (tdNumber === 13) {
                tableLoader.push(
                    <tr key={i}>
                        <td><i className="content-loader w-75" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-50" /></td>
                        <td><i className="content-loader w-icon" /></td>
                    </tr>
                )
            }
        }
        return tableLoader
    }

    static formatDatetime(datetime: string, withTime: boolean = false) {
        let format = 'MM-DD-YYYY';
        if (withTime) {
            format += ' HH:mm:ss';
        }

        if (datetime)
            return moment(datetime).format(format);
        else return null;
    }

    static formatDatetimeTimestamp(datetime: string, withTime: boolean = false) {
        let format = 'YYYY-MM-DD';
        if (withTime) {
            format += ' HH:mm:ss';
        }

        let dateTime;
        if (datetime) {
            dateTime = datetime
        } else {
            return null
        }

        return moment(dateTime).format(format);
    }

    static isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

    static arrayUnique(array) {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j] || this.isEqual(a[i], a[j]))
                    a.splice(j--, 1);
            }
        }

        return a;
    }

    static isEqual = (obj1, obj2) => {
        const obj1Keys = Object.keys(obj1);
        const obj2Keys = Object.keys(obj2);

        if (obj1Keys.length !== obj2Keys.length) {
            return false;
        }

        for (let objKey of obj1Keys) {
            if (obj1[objKey] !== obj2[objKey]) {
                return false;
            }
        }

        return true;
    };
}


export default Util;

