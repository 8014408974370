import {IValidator} from "./DataModelValidator";
import {ValidatorError} from "./errors/ValidatorError";
import {RequiredValidator} from "./RequiredValidator";

export abstract class RegexpValidator implements IValidator {

    /**
     * @inheritDoc
     */
    public validate(val: string): ValidatorError | boolean {
        const re: RegExp = this.getRegexp();

        const requiredValidator:RequiredValidator = new RequiredValidator();
        const valExists: boolean = (requiredValidator.validate(val) === true);
        const result: boolean = !valExists || re.test(val);

        return (result) ? true : ValidatorError.FORMAT_IS_NOT_VALID;
    }

    /**
     * Retrieve regexp to be matched.
     */
    protected abstract getRegexp(): RegExp;

    public getAdditionalParams(): any {
        return {};
    }
}