import {applyMiddleware, createStore, Reducer, Store} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {createLogger} from 'redux-logger';

export abstract class StoreSingletonFactory {
    private static store: Store;

    public static createStore(reducer: Reducer<any>): Store {
        const logger = createLogger({});

        if (!StoreSingletonFactory.store) {
            StoreSingletonFactory.store = createStore(reducer,
                composeWithDevTools(applyMiddleware())
            );
        }

        return StoreSingletonFactory.store;
    }

    public static getStore(): Store {
        return StoreSingletonFactory.store;
    }
}
