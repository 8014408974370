import * as React from 'react';
import {DataModel} from "../../../../model/DataModel";

export class FormHandlingUtil {
    private submitted = false;

    private inputs: { [key: string]: React.RefObject<any> };

    private _handleSubmit: (event: React.MouseEvent<HTMLElement> | KeyboardEvent | CustomEvent) => void;

    private models: DataModel<any>[];

    public constructor(inputs: { [key: string]: React.RefObject<any> },
                       handleSubmit: (event: React.MouseEvent<HTMLElement> | KeyboardEvent | CustomEvent) => void,
                       models: DataModel<any>[]) {
        this.inputs = inputs;
        this._handleSubmit = handleSubmit;
        this.models = models;
    }

    private handleKeyDown(event: KeyboardEvent) {
        const keyCode: number = event.keyCode;

        if (keyCode === 13) {
            this.getSubmitHandle()(event);
        }
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, isOnBlur: boolean = true) {
        let inputToFocus: any = null;

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                if (this.inputs[key].current) {
                    this.inputs[key].current.setError(errors[key]);
                    if (inputToFocus === null && !isOnBlur) {
                        if (this.inputs[key].current.inputRef.current
                            && this.inputs[key].current.inputRef.current.focus) {
                            inputToFocus = this.inputs[key].current.inputRef.current;
                        }
                    }
                }
            }
        }

        if (inputToFocus !== null) {
            inputToFocus.focus();
        }
    }

    private readInfo(): any {
        const plainObject: any = {};

        for (const key in this.inputs) {
            if (this.inputs.hasOwnProperty(key)) {
                if (this.inputs[key].current) {
                    plainObject[key] = this.inputs[key].current.getValue();
                    this.inputs[key].current.removeError();
                }
            }
        }

        this.models.forEach((model: DataModel<any>) => {
            model.setFromPlainObject(plainObject);
        });

        return plainObject;
    }

    private handleValidate(event: any): boolean {
        this.readInfo();

        let valid = true;
        this.models.forEach((model: DataModel<any>) => {
            valid = valid && model.validate();
        });

        if (this.submitted) {
            if (!valid) {
                let errors: { [attr: string]: string } = {};

                this.models.forEach((model: DataModel<any>) => {
                    const modelErrors: { [attr: string]: string } = model.getErrors();
                    errors = {...errors, ...modelErrors};
                });

                const isOnBlur: boolean = event.type === 'blur';
                this.fillInputsWithErrors(errors, isOnBlur);
            }
        }

        if (event.preventDefault) {
            event.preventDefault();
        }

        return valid;
    }

    public getValidateHandle(): (event: any) => void {
        const cb = (event: any): void => {
            this.handleValidate(event);
        };

        return cb.bind(this);
    }

    public getFillInputsWithErrorsHandle(): (errors: { [attr: string]: string }) => void {
        const cb = (errors: { [attr: string]: string }): void => {
            this.fillInputsWithErrors(errors);
        };

        return cb.bind(this);
    }

    public activateReturnButtonSubmit(): void {
        for (const key in this.inputs) {
            if (this.inputs.hasOwnProperty(key)) {
                if (this.inputs[key].current) {
                    if (this.inputs[key].current.inputRef.current &&
                        this.inputs[key].current.inputRef.current.addEventListener) {
                        this.inputs[key].current.inputRef.current
                            .addEventListener("keydown", this.handleKeyDown.bind(this), false);
                    }
                }
            }
        }
    }

    public setToModelsState(): void {
        let plainData: any = {};

        this.models.forEach((model: DataModel<any>) => {
            plainData = {...plainData, ...model.asPlainObject()};
        });

        for (const key in plainData) {
            if (plainData.hasOwnProperty(key)) {
                if (this.inputs[key] && this.inputs[key].current) {
                    this.inputs[key].current.setValue(plainData[key]);
                }
            }
        }
    }

    public getSubmitHandle(): (event: React.MouseEvent<HTMLElement> | KeyboardEvent | CustomEvent) => void {
        const cb = (event: React.MouseEvent<HTMLElement> | KeyboardEvent | CustomEvent): void => {
            this.submitted = true;

            this.readInfo();
            const valid: boolean = this.handleValidate(event);

            if (valid) {
                this._handleSubmit(event);
            }

            event.preventDefault();
        };

        return cb.bind(this);
    }

    public resetSubmitOperation(): void {
        this.submitted = false;
    }
}
