import React from 'react';
import VlbSimple from './VlbSimple';
import { Container, Button } from 'reactstrap';
import { Path } from '../container/Path';
import { Link } from 'react-router-dom';

export default class NotFoundPage extends VlbSimple {

    pageTitle() {
        return "404 Not Found" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
        ];
    }

    renderContent() {

        return (
            <div className="notfound-page">
                <div className="info-page-content d-flex justify-content-center align-items-center">
                    <Container >
                        <h1>404 Not Found</h1>
                        <p>Sorry, we couldn't find the page you were looking for. <br></br> We suggest that you return to dashboard page.</p>

                        <Button tag={Link} to={Path.AFFILIATES_STOCK} color="primary">Dashboard</Button>
                    </Container>
                </div>
            </div >
        );
    }
}