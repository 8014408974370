import React from 'react';
import VlbSimple from './VlbSimple';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Row, Col, Button, FormGroup, FormText } from 'reactstrap';
import logoOn from '../../assets/img/logo-on.png'
import Identity from '../user/Identity';
import { User, IUser } from '../models/User';
import { AxiosError, AxiosResponse } from 'axios';
import { EmailInput } from '../../common/components/widgets/form/input/EmailInput';
import Util from '../custom/Util';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import { IDestroy } from '../../common/components/pages/IDestroy';
import Noty from "noty";
import { TextInput } from '../../common/components/widgets/form/input/TextInput';


@withRouter(Path.FORGOTTEN_PASSWORD.toString(), PageContainer)
@withSecurity([Role.GUEST.toString()], Identity, Path.AFFILIATES_STOCK)
export default class ForgottenPasswordPage extends VlbSimple {
    private user: User = new User();

    private submitted: boolean = false;

    private emailInput: { [attr: string]: React.RefObject<any> } = {
        email: React.createRef(),
        username: React.createRef()
    };

    private tokenInput: { [attr: string]: React.RefObject<any> } = {
        token: React.createRef()
    };

    constructor(props: any) {
        super(props);

        this.state = {
            whichFrom: 'resetEmail',
            emailValue: '',
            usernameValue: ''
        }

        this.formDisplay = this.formDisplay.bind(this);
        this.readInfoEmail = this.readInfoEmail.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.showEmailSuccessNotification = this.showEmailSuccessNotification.bind(this);
        this.setDefaultInputs = this.setDefaultInputs.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.checkCode = this.checkCode.bind(this);
    }

    pageTitle() {
        return "Forgotten password" + super.pageTitle();
    }

    formDisplay(event: React.MouseEvent<HTMLInputElement>) {

        this.setDefaultInputs();
        this.submitted = false;

        this.setState({
            emailValue: '',
            usernameValue: ''
        })
    }

    sendEmail(event: React.MouseEvent<HTMLElement>) {

        this.submitted = true;

        if (this.validateEmail(event)) {
            this.sendRequest(event, this.emailInput);
        }
    }

    checkCode(event: React.MouseEvent<HTMLElement>) {

        event.preventDefault();
        let token = this.tokenInput["token"].current.getValue();

        this.user.forgottenPasswordCheckToken(token + "_")
            .then((response: AxiosResponse) => {

                if (response.data.password_reset_token) {
                    document.location.pathname = Path.NEW_PASSWORD + response.data.password_reset_token;
                } else {
                    document.location.pathname = Path.NEW_PASSWORD + token;
                }
            })
            .catch((error: AxiosError) => {
                const aError: AxiosError = error;

                switch (aError.response.status) {
                    case 404:
                    case 403:
                        Util.notification("error", "There was an error!", 1500);
                        break;
                    case 422:
                    case 500:
                        const errors: { [attr: string]: string } = {};
                        errors["token"] = "Code is invalid.";
                        this.fillInputsWithErrors(errors, this.tokenInput);

                        break;
                    default:
                }
            });
    }

    showEmailSuccessNotification() {

        new Noty({
            type: 'success',
            theme: 'bootstrap-v4',
            layout: 'topRight',
            text: 'Email successfully sent.',
        }).show();
    }

    sendRequest(event: React.MouseEvent<HTMLElement>, inputs: any) {

        this.submitted = true;
        let plainObject: IUser;
        this.user = new User();

        if (inputs.hasOwnProperty("email")) {
            plainObject = this.readInfoEmail();
        }

        this.user.setFromPlainObject(plainObject);

        this.user.forgottenPasswordRequest(this.user)
            .then((response: AxiosResponse) => {

                this.setDefaultInputs();

                if (inputs.hasOwnProperty("email")) {
                    this.showEmailSuccessNotification();
                    this.setState({ emailValue: "", usernameValue: "" });
                }
            })
            .catch((error: AxiosError) => {
                Util.notification("success", "Code successfully sent.", 2000);
            });


    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user
        ];
    }

    setDefaultInputs(): void {

        this.emailInput = {
            email: React.createRef(),
            username: React.createRef()
        }

    }

    private readInfoEmail(): IUser {

        const plainObject: IUser = {};

        for (const key in this.emailInput) {

            if (this.emailInput.hasOwnProperty(key) && this.emailInput[key].current) {
                plainObject[key] = this.emailInput[key].current.getValue();
                this.emailInput[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    private validateEmail(event: any): boolean {
        this.readInfoEmail();

        //promjena scenarija
        this.user.changeScenario(User.SCENARIO.CHANGE_PASSWORD_EMAIL);

        const valid = this.user.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.user.getErrors();
                this.fillInputsWithErrors(errors, this.emailInput);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    onEmailChange(event: React.FormEvent<HTMLInputElement>) {
        let target = event.target as HTMLInputElement;
        this.setState({ emailValue: target.value });
    }

    onUsernameChange(event: React.FormEvent<HTMLInputElement>) {
        let target = event.target as HTMLInputElement;
        this.setState({ usernameValue: target.value });
    }

    renderContent() {

        return (
            <div className="forgotten-password-page">

                <div className="form d-flex justify-content-center align-items-center min-vh-100">
                    <div className="max-screen-440">
                        <Row>
                            <Col xs="12">
                                <div className="logo-form mb-3"><img alt="logo" src={logoOn} /></div>
                            </Col>
                            <Col xs="12">
                                <form>
                                <FormGroup>
                                        <TextInput
                                            id="Username"
                                            label="ESM-Username"
                                            placeholder="Enter your username"
                                            ref={this.emailInput.username}
                                            onChange={this.onUsernameChange}
                                            initialValue={this.state.usernameValue}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <EmailInput
                                            id="Email"
                                            label="Email address"
                                            placeholder="Enter your email address"
                                            ref={this.emailInput.email}
                                            onChange={this.onEmailChange}
                                            initialValue={this.state.emailValue}
                                        />
                                        <FormText>
                                            You will be sent a link which you can use to change your password
                                    </FormText>
                                    </FormGroup>
                                    <Button color="primary" className="mt-3" onClick={this.sendEmail}>Reset my password</Button> {/* Type of button must be submit */}
                                </form>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div >
        );
    }
}
