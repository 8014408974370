export enum ValidatorError {
    FORMAT_IS_NOT_VALID,

    ADEQUATE_VALUE_IS_NOT_SELECTED,

    REQUIRED,

    ONE_OF_REQUIRED,

    VALUE_DOES_NOT_MATCH,

    LENGTH_NOT_IN_BETWEEN,

    LENGTH_IS_BELLOW_ALLOWED,

    LENGTH_IS_ABOVE_ALLOWED,

    DATE_MUST_BE_BEFORE,

    DATE_MUST_BE_BEFORE_OR_SAME,

    DATE_MUST_BE_AFTER_OR_SAME,

    DATE_MUST_BE_AFTER,

    DATES_MINIMAL_MONTHS_DIFFERENCE_IS_NOT_SATISFIED,

    DATES_MAXIMAL_MONTHS_DIFFERENCE_IS_NOT_SATISFIED,

    VALUE_TOO_LARGE,

    VALUE_TOO_LOW
}
