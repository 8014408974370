/**
 * Paths enum to define page paths.
 */
export enum Path {
    AFFILIATES_STOCK = "/",

    ALL_AFFILIATES = "/affiliates",

    AFFILIATES_CONTACTS = "/affiliates-contacts",

    AFFILIATE_CONTACTS = "/affiliates-contacts/:id",

    UPLOAD_STATUS = "/upload-status",

    USERS = "/users",

    LOGIN = "/login",

    FORGOTTEN_PASSWORD = "/forgotten-password",

    NEW_PASSWORD = "/new-password/",

    FORGOTTEN_PASSWORD_CHANGE = "/new-password/:token",

    NOTFOUND = "/404-page",

    FORBIDDEN = "/forbidden",

    GENERAL_SETTINGS = "/general-settings",

    PROFILE_SETTINGS = "/profile-settings",

    LOGIN_TEXT = "/login-text",
}
