import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import './assets/font/style.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "noty/lib/noty.css";
import "noty/lib/themes/bootstrap-v4.css";
import NotFoundPage from "./components/pages/NotFoundPage"; 

import * as Container from "./common/components/container/Container";
import { ContainerElements } from "./components/container/ContainerElements";
import ErrorBoundary from './components/custom/ErrorBoundary';

export type ContainerType = typeof Container;

Container.set404(NotFoundPage);

export { Container };


ContainerElements.addAll();

ReactDOM.render(<ErrorBoundary> <App /> </ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
