import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface IAffiliate {
    id?: number,
    name?: string,
    abbreviation?: string,
    stock_file_code?: string,
    currency_cost_prices?: string,
    intercompany_price_information?: string,
}


export class Affiliate extends DataModel<IAffiliate> {
    public resourceName = "affiliate";
    public resourceNamePlural = "affiliates";

    private _id: number;
    private _name: string;
    private _abbreviation: string;
    private _stock_file_code: string;
    private _currency_cost_prices: string;
    private _intercompany_price_information: string;


    protected getDefaultValues(): IAffiliate {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IAffiliate> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IAffiliate> {
        return new DataModelValidator<IAffiliate>({
            name: [new RequiredValidator(), new LengthValidator({ max: 100 })],
            abbreviation: [new RequiredValidator(), new LengthValidator({ min: 3 })]
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IAffiliate>, action: AnyAction): IDataModelState<IAffiliate> {
        return state;
    }

    public loadAffiliates(): Promise<IAffiliate[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural)
            .getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IAffiliate | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                abbreviation: this._abbreviation,
                stock_file_code: this._stock_file_code,
                currency_cost_prices: this._currency_cost_prices,
                intercompany_price_information: this._intercompany_price_information
            });
    }

    public update(data: IAffiliate): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                name: this._name,
                abbreviation: this._abbreviation,
                stock_file_code: this._stock_file_code,
                currency_cost_prices: this._currency_cost_prices,
                intercompany_price_information: this._intercompany_price_information
            });
    }



    getStoreKey(): string {
        return "AFFILIATE";
    }

    protected setFromObj(data: IAffiliate): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._abbreviation = DataModel.safeGet(data.abbreviation, this._abbreviation);
        this._stock_file_code = DataModel.safeGet(data.stock_file_code, this._stock_file_code);
        this._currency_cost_prices = DataModel.safeGet(data.currency_cost_prices, this._currency_cost_prices);
        this._intercompany_price_information = DataModel.safeGet(data.intercompany_price_information, this._intercompany_price_information);
    }


    protected toObj(): IAffiliate {
        return {
            id: this._id,
            name: this._name,
            abbreviation: this._abbreviation,
            stock_file_code: this._stock_file_code,
            currency_cost_prices: this._currency_cost_prices,
            intercompany_price_information: this._intercompany_price_information
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    get abbreviation() {
        return this._abbreviation;
    }

    get stock_file_code() {
        return this._stock_file_code;
    }

    get currency_cost_prices() {
        return this._currency_cost_prices;
    }

    get intercompany_price_information() {
        return this._intercompany_price_information;
    }
}
