import { Container } from "../../index";
import { PageType } from "../../common/components/pages/Page";
import { DataModel } from "../../common/model/DataModel";

import { User } from "../models/User";

import AffiliateStockPage from "../pages/AffiliateStockPage";
import AffiliatesPage from "../pages/AffiliatesPage";
import AffiliatesContactPage from "../pages/AffiliatesContactPage";
import UsersPage from "../pages/UsersPage";
import UploadStatusPage from "../pages/UploadStatusPage";
import ForbiddenPage from "../pages/ForbiddenPage";
import ProfileSettingsPage from "../pages/ProfileSettingsPage";
import ForgottenPasswordPage from "../pages/ForgottenPasswordPage";
import ForgottenPasswordChangePage from "../pages/ForgottenPasswordChangePage";
import LoginPage from "../pages/LoginPage";
import { Affiliate } from "../models/Affiliate";
import { UploadStatus } from "../models/UploadStatus";
import { Article } from "../models/Article";
import { AffiliateContact } from "../models/AffiliateContact";
import LoginTextPage from "../pages/LoginTextPage";
import { LoginText } from "../models/LoginText";

/**
 * Util static class for managing Container Elements.
 */
export abstract class ContainerElements {
    /**
     * Add all necessary items to container.
     */
    public static addAll() {
        this.addPages();
        this.addModels();
    }

    /**
     * Add needed pages to container.
     */
    private static addPages() {
        const pages: PageType[] = [
            AffiliateStockPage,
            AffiliatesPage,
            AffiliatesContactPage,
            UsersPage,
            UploadStatusPage,
            ForbiddenPage,
            ProfileSettingsPage,
            ForgottenPasswordPage,
            ForgottenPasswordChangePage,
            LoginPage,
            LoginTextPage
        ];

        pages.forEach((page: PageType) => {
            Container.addPage(page);
        });
    }

    /**
     * Add models to container.
     */
    private static addModels() {
        const models: DataModel<any>[] = [
            new User(false),
            new Affiliate(false),
            new UploadStatus(false),
            new Article(false),
            new AffiliateContact(false),
            new LoginText(false)
        ];

        models.forEach((model: DataModel<any>) => {
            Container.addModel(model);
        });
    }
}
