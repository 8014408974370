import { Action } from "redux";

export interface NEW_ITEM<S> extends Action {
    type: "@@DataModel/NEW_ITEM",
    storeKey: string,
    payload: S
}

export interface UPDATE_STATE<S> extends Action {
    type: "@@DataModel/UPDATE_STATE",
    storeKey: string,
    payload: S
}

export interface DESTROY_STATE<S> extends Action {
    type: "@@DataModel/DESTROY_STATE",
    storeKey: string
}

export enum ActionTypes {

    NEW_ITEM = "@@DataModel/NEW_ITEM",

    UPDATE_STATE = "@@DataModel/UPDATE_STATE",

    DESTROY_STATE = "@@DataModel/DESTROY_STATE"

}
