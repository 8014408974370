import React, { Component } from 'react';
import { Navbar, NavbarToggler, Nav, Collapse, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import { Path } from '../../container/Path';

import { User } from '../../models/User';
import Identity from '../../user/Identity';
import { Role } from '../../user/Role';

interface IHeaderNavState {
    isOpen: boolean;
}

class HeaderNav extends Component<{}, IHeaderNavState> {
    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    protected logout(event: React.MouseEvent<HTMLElement>): void {

        const user: User = new User(false);
        Identity.eraseUserIdentity();

        user.logout().then(() => {
            document.location.pathname = Path.AFFILIATES_STOCK.toString();
        }).catch((error) => {
            console.log(error)
        });

        event.preventDefault();
    }

    render() {

        return (
            <>
                <Navbar expand="md" className="w-100 py-0 header-navigation">
                    <Logo />
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto nav-font" navbar>
                            <NavItem>
                                <NavLink tag={Link} to={Path.AFFILIATES_STOCK}>Affiliate stock</NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>Affiliates</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem tag={Link} to={Path.ALL_AFFILIATES}>All affiliates</DropdownItem>
                                    <DropdownItem tag={Link} to={Path.AFFILIATES_CONTACTS}>Affiliates contacts</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {Identity.role == Role.ADMIN ?
                                (<><NavItem>
                                    <NavLink tag={Link} to={Path.USERS}>Users</NavLink>
                                </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to={Path.UPLOAD_STATUS}>Upload status</NavLink>
                                    </NavItem>
                                </>)
                                : null}


                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>Profile</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem tag={Link} to={Path.PROFILE_SETTINGS}>Profile settings</DropdownItem>
                                    {Identity.role == Role.ADMIN ?
                                        <DropdownItem tag={Link} to={Path.LOGIN_TEXT}>Login text</DropdownItem>
                                        : null}
                                    <DropdownItem divider />
                                    <DropdownItem tag="a" className="log-out" onClick={this.logout}>Logout</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </>
        );
    }
}

export default HeaderNav
