import React from 'react';
import logoimg from '../../../assets/img/logo.png';
import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Path } from '../../container/Path';

function Logo() {
    return (
        <NavbarBrand tag={'div'}>
            <Link to={Path.AFFILIATES_STOCK} className="logo-box">
                <img className="logo my-2" alt="logo" src={logoimg} />
            </Link>
        </NavbarBrand>
    );
}

export default Logo;