import * as React from "react";
import {ContainerType} from "../../index";
import {Provider} from "react-redux";
import {DataModel} from "./DataModel";
import {AnyAction, Reducer, Store} from "redux";
import {StoreSingletonFactory} from "./StoreSingletonFactory";

export interface IStoreProviderProps {
    container: ContainerType
}

/**
 * Store provider for generating necessary redux mappings between data models and redux store.
 */
export class StoreProvider extends React.Component<IStoreProviderProps> {

    /**
     * @inheritDoc
     */
    public render() {
        const container: ContainerType = this.props.container;

        const initialState:any = {};

        container.getModels().forEach((model: DataModel<any>) => {
            initialState[model.getStoreKey()] = {all: {}};
        });

        const mainReducer: Reducer<any> = (state: any = initialState, action: AnyAction) => {
            container.getModels().forEach((model: DataModel<any>) => {
                state[model.getStoreKey()] = model.reducer(state[model.getStoreKey()], action);
            });

            return {...state};
        };

        const store:Store = StoreSingletonFactory.createStore(mainReducer);

        return (<Provider store={store}>
            {this.props.children}
        </Provider>);
    }
}
