import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IAffiliate } from "./Affiliate";

export interface IArticle {
    id?: number,
    affiliate_id?: number,
    affiliate?: IAffiliate,
    code?: string,
    vlunar_code?: string,
    od_1?: string,
    od_2?: string,
    od_3?: string,
    wt_1?: string,
    wt_2?: string,
    type?: string,
    specification?: string,
    grade?: string,
    heat_treatment?: string,
    length?: string,
    ends?: string,
    surface?: string,
    cdi?: boolean,
    supplier_approved?: boolean,
    certificate?: string,
    other?: string,
    version?: string,
    size_short?: string,
    size?: string,
    description?: string,
    available_stock?: number,
    uom?: string,
    cost_price?: number,
    sales_price?: number,
    max_discount?: string,
    replacement_value?: number,
    weight?: number | string,
    out_going?: string,
    created_at?: string,
}


export class Article extends DataModel<IArticle> {
    public resourceName = "article";
    public resourceNamePlural = "articles";

    private _id?: number;
    private _affiliate_id: number;
    private _affiliate: IAffiliate;
    private _code: string;
    private _vlunar_code: string;
    private _od_1: string;
    private _od_2: string;
    private _od_3: string;
    private _wt_1: string;
    private _wt_2: string;
    private _type: string;
    private _specification: string;
    private _grade: string;
    private _heat_treatment: string;
    private _length: string;
    private _ends: string;
    private _surface: string;
    private _cdi: boolean;
    private _supplier_approved: boolean;
    private _certificate: string;
    private _other: string;
    private _version: string;
    private _size_short: string;
    private _size: string;
    private _description: string;
    private _available_stock: number;
    private _uom: string;
    private _cost_price: number;
    private _sales_price: number;
    private _max_discount: string;
    private _replacement_value: number;
    private _weight: number | string;
    private _out_going: string;
    private _created_at: string;


    protected getDefaultValues(): IArticle {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IArticle> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IArticle> {
        return new DataModelValidator<IArticle>({
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IArticle>, action: AnyAction): IDataModelState<IArticle> {
        return state;
    }

    public loadArticles(): Promise<IArticle[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListEnds(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/ends").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListGrade(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/grade").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListSpecification(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/specification").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListType(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/type").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListSurface(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/surface").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListOther(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/other").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListCertificate(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/certificates").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListHT(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + "/heat-treatment").getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IArticle | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "ARTICLE";
    }

    protected setFromObj(data: IArticle): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._affiliate_id = DataModel.safeGet(data.affiliate_id, this._affiliate_id) as number;
        this._affiliate = DataModel.safeGet(data.affiliate, this._affiliate);
        this._code = DataModel.safeGet(data.code, this._code);
        this._vlunar_code = DataModel.safeGet(data.vlunar_code, this._vlunar_code);
        this._od_1 = DataModel.safeGet(data.od_1, this._od_1);
        this._od_2 = DataModel.safeGet(data.od_2, this._od_2);
        this._od_3 = DataModel.safeGet(data.od_3, this._od_3);
        this._wt_1 = DataModel.safeGet(data.wt_1, this._wt_1);
        this._wt_2 = DataModel.safeGet(data.wt_2, this._wt_2);
        this._type = DataModel.safeGet(data.type, this._type);
        this._specification = DataModel.safeGet(data.specification, this._specification);
        this._grade = DataModel.safeGet(data.grade, this._grade);
        this._heat_treatment = DataModel.safeGet(data.heat_treatment, this._heat_treatment);
        this._length = DataModel.safeGet(data.length, this._length);
        this._ends = DataModel.safeGet(data.ends, this._ends);
        this._surface = DataModel.safeGet(data.surface, this._surface);
        this._cdi = DataModel.safeGet(data.cdi, this._cdi);
        this._supplier_approved = DataModel.safeGet(data.supplier_approved, this._supplier_approved);
        this._certificate = DataModel.safeGet(data.certificate, this._certificate);
        this._other = DataModel.safeGet(data.other, this._other);
        this._version = DataModel.safeGet(data.version, this._version);
        this._size_short = DataModel.safeGet(data.size_short, this._size_short);
        this._size = DataModel.safeGet(data.size, this._size);
        this._description = DataModel.safeGet(data.description, this._description);
        this._available_stock = DataModel.safeGet(data.available_stock, this._available_stock);
        this._uom = DataModel.safeGet(data.uom, this._uom);
        this._cost_price = DataModel.safeGet(data.cost_price, this._cost_price);
        this._sales_price = DataModel.safeGet(data.sales_price, this._sales_price);
        this._max_discount = DataModel.safeGet(data.max_discount, this._max_discount);
        this._replacement_value = DataModel.safeGet(data.replacement_value, this._replacement_value) as number;
        this._weight = DataModel.safeGet(data.weight, this._weight) as number;
        this._out_going = DataModel.safeGet(data.out_going, this._out_going);
        this._created_at = DataModel.safeGet(data.created_at, this._created_at);
    }


    protected toObj(): IArticle {
        return {
            id: this._id,
            affiliate_id: Number(this._affiliate_id),
            affiliate: this._affiliate,
            code: this._code,
            vlunar_code: this._vlunar_code,
            od_1: this._od_1,
            od_2: this._od_2,
            od_3: this._od_3,
            wt_1: this._wt_1,
            wt_2: this._wt_2,
            type: this._type,
            specification: this._specification,
            grade: this._grade,
            heat_treatment: this._heat_treatment,
            length: this._length,
            ends: this._ends,
            surface: this._surface,
            cdi: this._cdi,
            supplier_approved: this._supplier_approved,
            certificate: this._certificate,
            other: this._other,
            version: this._version,
            size_short: this._size_short,
            size: this._size,
            description: this._description,
            available_stock: this._available_stock,
            uom: this._uom,
            cost_price: this._cost_price,
            sales_price: this._sales_price,
            max_discount: this._max_discount,
            replacement_value: this._replacement_value,
            weight: this._weight,
            out_going: this._out_going,
            created_at: this._created_at,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    set affiliate_id(id: number) {
        this._affiliate_id = id;
    }

    get affiliate_id() {
        return this._affiliate_id;
    }

    get code() {
        return this._code;
    }
    get vlunar_code() {
        return this._vlunar_code;
    }
    get od_1() {
        return this._od_1;
    }
    get od_2() {
        return this._od_2;
    }
    get od_3() {
        return this._od_3;
    }
    get wt_1() {
        return this._wt_1;
    }
    get wt_2() {
        return this._wt_2;
    }
    get type() {
        return this._type;
    }
    get specification() {
        return this._specification;
    }
    get grade() {
        return this._grade;
    }
    get heat_treatment() {
        return this._heat_treatment;
    }
    get length() {
        return this._length;
    }
    get ends() {
        return this._ends;
    }
    get surface() {
        return this._surface;
    }
    get cdi() {
        return this._cdi;
    }
    get supplier_approved() {
        return this._supplier_approved;
    }
    get certificate() {
        return this._certificate;
    }
    get other() {
        return this._other;
    }
    get version() {
        return this._version;
    }
    get size_short() {
        return this._size_short;
    }
    get size() {
        return this._size;
    }
    set size(size: any) {
        this._size = size;
    }
    get description() {
        return this._description;
    }
    get available_stock() {
        return this._available_stock;
    }
    get uom() {
        return this._uom;
    }
    get cost_price() {
        return this._cost_price;
    }
    get sales_price() {
        return this._sales_price;
    }
    get max_discount() {
        return this._max_discount;
    }
    get replacement_value() {
        return this._replacement_value;
    }
    get out_going() {
        return this._out_going;
    }

    set out_going(og: string | null) {
        this._out_going = og;
    }
    get weight() {
        return this._weight;
    }
    set weight(weight: number | string) {
        this._weight = weight;
    }
    get created_at() {
        return this._created_at;
    }
}
