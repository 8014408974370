import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Path } from '../container/Path';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <Container fluid>
                    <div className="copyright">© 2020 Copyright <Link to={Path.AFFILIATES_STOCK}>VanLeeuwen</Link></div>
                </Container>
            </footer>
        );
    }
}

export default Footer;