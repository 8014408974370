import React from 'react';
import VlbPage from './VlbPage';
import { withRouter } from '../../common/components/routing/Router';
import { Path } from '../container/Path';
import { Container as PageContainer } from "../../index";

import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Identity from '../user/Identity';

import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';

import { DataProvider } from '../../common/model/DataProvider';
import ReduxComponentWrapper from '../../common/components/widgets/reduxConnected/ReduxComponentWrapper';
import { TablePage } from '../../common/components/widgets/table/TablePage';
import { IRestDataSourceParams } from '../../common/dataSource/IRestDataSourceParams';
import { DataColumn } from '../../common/components/widgets/table/column/DataColumn';
import { BasicSorter } from '../../common/components/widgets/table/sorter/BasicSorter';
import { BasicFilter } from '../../common/components/widgets/table/filter/BasicFilter';
import { ActionColumn } from '../../common/components/widgets/table/column/ActionColumn';
import { AxiosResponse, AxiosError } from 'axios';
import Util from '../custom/Util';
import { BasicPaging } from '../../common/components/widgets/table/paging/BasicPaging';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { Affiliate, IAffiliate } from '../models/Affiliate';
import { TextAreaInput } from '../../common/components/widgets/form/input/TextAreaInput';
import { Redirect } from 'react-router-dom';

@withRouter(Path.ALL_AFFILIATES.toString(), PageContainer)
@withSecurity([Role.ADMIN.toString(), Role.USER.toString()], Identity, Path.AFFILIATES_STOCK)
class AffiliatesPage extends VlbPage {
  private submitted = false;
  private modalTitle = "Add new";
  private model = new Affiliate(false);
  private dataProvider = new DataProvider<IAffiliate>();

  private affiliateInputs: { [attr: string]: React.RefObject<any> } = {
    name: React.createRef(),
    abbreviation: React.createRef(),
    stock_file_code: React.createRef(),
    currency_cost_prices: React.createRef(),
    intercompany_price_information: React.createRef(),
  };

  constructor(props: any) {
    super(props);

    this.delete = this.delete.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.readInputs = this.readInputs.bind(this);
    this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setInputValues = this.setInputValues.bind(this);

    this.state = {
      modalDelete: false,
      currentModelDelete: {},
      modalFormOpen: false,
      modalEdit: false,
      reload: false,
      isCreate: true,

      modalFile: false,
      currentModelFile: '',
    };
  }

  pageTitle() {
    return "All affiliates" + super.pageTitle();
  }

  getDestroyableMembers() {
    return [
    ];
  }

  delete(row?: any) {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      currentModelDelete: row
    }));
  }

  add() {
    this.modalTitle = "Add new ";
    this.model.changeScenario(this.state.scenarios ? this.state.scenarios.create : "default");

    this.setState(prevState => ({
      modalFormOpen: !prevState.modalFormOpen,
      isCreate: true
    }));
  }

  edit(row?: any) {
    this.modalTitle = "Edit ";
    this.model.changeScenario(this.state.scenarios ? this.state.scenarios.update : "default");

    this.model.loadById(row.id)
      .then((response: any) => {
        this.model.setFromPlainObject(response);
        this.setInputValues(response);

      })
      .catch((error: AxiosError) => {
        Util.notification("error", "Data could not be loaded", 1500);
      });

    this.setState(prevState => ({
      isCreate: false,
      modalFormOpen: !prevState.modalFormOpen
    }));
  }

  setInputValues(values: any) {

    let inputs = this.affiliateInputs;

    for (let key in values) {
      if (inputs[key] && inputs[key].current) {
        inputs[key].current.setValue(values[key]);
      }
    }
  }

  protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {
    let promise: Promise<AxiosResponse>;
    this.submitted = true;

    if (this.validate(event)) {

      if (this.state.isCreate) {
        promise = this.model.createNew();
      } else {
        promise = this.model.update(this.model);
      }

      promise.then((response: AxiosResponse) => {

        Util.notification("success", "Success.", 2000);
        this.setState({ reload: true, modalFormOpen: false, modalEdit: false })

      })
        .catch((error: AxiosError) => {
          const aError: AxiosError = error;

          switch (aError.response.status) {
            case 422:

              if (aError.response && aError.response.data && aError.response.data.errors) {
                const respErrors: any[] = aError.response.data.errors;
                const errors: { [attr: string]: string } = {};

                respErrors.forEach((error: any) => {
                  errors[error.fieldName] = error.errorMessage;
                });

                this.fillInputsWithErrors(errors, this.affiliateInputs);
              }
              break;
            default:

              Util.notification("error", "Error.", 2000);
              break;
          }

          this.setState({ reload: true })
        });
    }
  }


  private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && inputs[key] !== undefined && inputs[key].current) {
        inputs[key].current.setError(errors[key]);
      }
    }
  }

  private validate(event: any): boolean {
    this.readInputs();

    let inputs = this.affiliateInputs;
    const valid = this.model.validate();

    if (this.submitted && !valid) {
      const errors: { [attr: string]: string } = this.model.getErrors();
      this.fillInputsWithErrors(errors, inputs);
    }

    event.preventDefault();

    return valid;
  }

  private readInputs(): any {

    const plainObject = {};
    const inputs = this.affiliateInputs;

    for (const key in inputs) {
      if (inputs.hasOwnProperty(key) && inputs[key].current) {
        plainObject[key] = inputs[key].current.getValue();
        inputs[key].current.removeError();
      }
    }

    this.model.setFromPlainObject({ ...this.model.asPlainObject(), ...plainObject });

    return plainObject;
  }

  info(row?: any) {
    if (row.intercompany_price_information) {
      this.setState(prevState => ({
        modalFile: !prevState.modalFile,
        currentModelFile: row
      }));
    } else {
      Util.notification("info", "No information", 1500)
    }
  }

  affiliateContacts(row?: any) {
    this.props.history.push(Path.AFFILIATES_CONTACTS + '/' + row.id);
  }

  renderContent() {

    const affiliateColumns: any[] = [
      new DataColumn('Affiliate', 'name', 20, new BasicSorter(), new BasicFilter(false, 'Search affiliate name', 8)), //isnumber, placeholder, colsize
      new DataColumn('Abbreviation', 'abbreviation', 15, new BasicSorter(), new BasicFilter(false, 'Search affiliate Abbreviation ', 4)),
      new DataColumn('Stock file code', 'stock_file_code', 20, new BasicSorter()),
      new DataColumn('Currency of cost prices.', 'currency_cost_prices', 20, new BasicSorter()),
      new ActionColumn("", null, null, 10,
        [{
          operationCb: (row: any) => {
            this.info(row)
          },
          iconClassName: "table-edit view",
          message: null,
          icon: <FontAwesomeIcon icon={faInfoCircle} />,
          iconStyle: {}
        }],
        "Intercompany price information"
      ),
    ];

    if(Identity.role != Role.USER){
      affiliateColumns.push(
        new ActionColumn(null,
          (row: Object) => {
            this.delete(row)
          },
          (row: Object) => {
            this.edit(row)
          },
          10,
          [{
            operationCb: (row: any) => {
              this.affiliateContacts(row)
            },
            iconClassName: "float-left table-edit view",
            message: null,
            icon: <FontAwesomeIcon icon={faAddressBook} />,
            iconStyle: {}
          }]
        )
      )
    }



    let info;
    info = (
      <Modal centered size="lg" isOpen={this.state.modalFile} fade={false} toggle={() => this.setState({ modalFile: !this.state.modalFile })}>
        <ModalHeader toggle={() => this.setState({ modalFile: !this.state.modalFile })}>{this.state.currentModelFile.name} log</ModalHeader>
        <ModalBody>
          {this.state.currentModelFile ? this.state.currentModelFile.intercompany_price_information : null}
        </ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalFile: !this.state.modalFile })}>Close</Button>
        </ModalFooter>
      </Modal>
    )

    let modal;
    modal = (
      <Modal centered size="md" isOpen={this.state.modalFormOpen} fade={false} toggle={() => this.add()}>
        <>
          <ModalHeader toggle={() => this.add()}>{this.modalTitle} user</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <TextInput
                  label="Affiliate name"
                  id="a_name"
                  placeholder="Affiliate name"
                  ref={this.affiliateInputs.name}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  label="Afffilate abbreviation"
                  id="a_abbreviation"
                  placeholder="Afffilate abbreviation"
                  ref={this.affiliateInputs.abbreviation}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  label="Affilate stock file code"
                  id="a_stock_file_code"
                  placeholder="Affilate stock file code"
                  ref={this.affiliateInputs.stock_file_code}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  label="Currency of cost prices"
                  id="a_currency_cost_prices"
                  placeholder="Currency of cost prices"
                  ref={this.affiliateInputs.currency_cost_prices}
                />
              </FormGroup>
              <FormGroup>
                <TextAreaInput
                  label="Intercompany price information"
                  id="a_intercompany_price_information"
                  placeholder="Intercompany price information"
                  ref={this.affiliateInputs.intercompany_price_information}
                  rows={5}
                />
              </FormGroup>

            </Form>
          </ModalBody>
          <ModalFooter>
            <Button outline color="secondary" onClick={() => this.setState({ modalFormOpen: !this.state.modalFormOpen })}>Cancel</Button>
            <Button outline color="primary" onClick={this.handleSubmit}>{this.state.isCreate ? "Add" : "Update"}</Button>
          </ModalFooter>
        </>
      </Modal>
    )

    let deleteModal;
    deleteModal = (
      <Modal centered size="md" isOpen={this.state.modalDelete} fade={false} toggle={this.delete}>
        <ModalHeader toggle={this.delete}>{this.state.currentModelDelete.name}</ModalHeader>
        <ModalBody>Are you sure you want to delete this affiliate?</ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={() => this.setState({ modalDelete: !this.state.modalDelete })}>Cancel</Button>
          <Button outline color="primary" onClick={() =>
            (this.model).del(this.state.currentModelDelete["id"]).then(() => {
              this.setState(prevState => ({
                modalDelete: !prevState.modalDelete,
              }));
            })
          }>Delete</Button>
        </ModalFooter>
      </Modal>
    )

    const paging = new BasicPaging({
      pageSize: 15
    });

    return (
      <Container fluid className="page-content">

        <div className="title-box">
          <h1 className="page-title">Affiliates</h1>
          {Identity.role == Role.ADMIN ?
            (<div className="add-new"><Button color="primary" onClick={() => this.add()}><FontAwesomeIcon icon={faPlus} /> Add affiliate</Button></div>
            ) : null}
        </div>

        <div className="element-box">
          <ReduxComponentWrapper component={TablePage} componentPropsCallback={(state: any) => ({
            provider: this.dataProvider,
            loader: [6, 15],
            searchCallback: (params: IRestDataSourceParams): void => {
              if(params.filters){
                params.filters.push({
                  attr: "status_id",
                  operator: "eq",
                  val: 1
                })
              }else{
                params.filters = [
                  {
                    attr: "status_id",
                    operator: "eq",
                    val: 1
                  }
                ];
              }
              this.dataProvider.refreshWithHeaders(    
                this.model.getListPlain({
                  ...params,
                  sort: {
                    attr: "created_at",
                    ascending: false
                  }
                })
              )
            },
            columns: affiliateColumns,
            paging: paging
          })} />

          {modal}
          {deleteModal}
          {info}

        </div>
      </Container>
    );
  }
}

export default AffiliatesPage;

