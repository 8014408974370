import Page from "../../common/components/pages/Page";
import MainLayout from "../layout/MainLayout";


export default abstract class VlbPage<P extends any = any, S extends any = any> extends Page<P, S> {

    componentDidMount() {
        super.componentDidMount();
    }

    pageTitle() {
        return " - " + process.env.REACT_APP_TITLE_PREFIX;
    }

    pageLayout() {
        return new MainLayout();
    }
}
