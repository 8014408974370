import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { EmailValidator } from "../../common/components/validators/EmailValidator";
import { OneRequiredValidator } from "../../common/components/validators/OneRequiredValidator";
import { IAffiliate } from "./Affiliate";
import { SelectedValidator } from "../../common/components/validators/SelectedValidator";
import { NumberValidator } from "../../common/components/validators/NumberValidator";
import { SelectIdValidator } from "../../common/components/validators/SelectIdValidator";

export interface IAffiliateContact {
    id?: number,
    name?: string,
    affiliate_id?: number,
    afffiliate?: IAffiliate,
    additional_info?: string,
    email?: string,
    phone_number?: string,
}


export class AffiliateContact extends DataModel<IAffiliateContact> {
    public resourceName = "contact";
    public resourceNamePlural = "contacts";

    private _id: number;
    private _name: string;
    private _affiliate_id: number;
    private _affiliate: IAffiliate;
    private _additional_info: string;
    private _email: string;
    private _phone_number: string;


    protected getDefaultValues(): IAffiliateContact {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceNamePlural, id?: number): RestDataSource<IAffiliateContact> {
        let affiliate_id = id ? id + '/' : ''
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + 'affiliate/' + affiliate_id + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IAffiliateContact> {
        return new DataModelValidator<IAffiliateContact>({
            name: [new RequiredValidator(), new LengthValidator({ max: 100 })],
            additional_info: [new LengthValidator({ max: 500 })],
            affiliate_id: [new RequiredValidator(), new SelectIdValidator()],
            email: [new RequiredValidator(), new EmailValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IAffiliateContact>, action: AnyAction): IDataModelState<IAffiliateContact> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadContactsById(id: number): Promise<IAffiliateContact[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural, id)
            .getList({});
    }

    public loadById(id: number, affiliate_id: number): Promise<IAffiliateContact | AxiosError> {
        return this.createDataSource(this.resourceName, affiliate_id)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public del(id: number, affiliate_id: number): Promise<void> {
        return this.createDataSource(this.resourceName, affiliate_id)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    public createNew(id: number): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName, id)
            .plainPostOperation({
                name: this._name,
                email: this._email,
                additional_info: this._additional_info,
                phone_number: this._phone_number,
                affiliate_id: Number(this._affiliate_id),
                afffiliate: this._affiliate
            });
    }

    public update(data: IAffiliate, id: number): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName, id)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                name: this._name,
                email: this._email,
                additional_info: this._additional_info,
                phone_number: this._phone_number,
                affiliate_id: Number(this._affiliate_id),
                afffiliate: this._affiliate
            });
    }

    getStoreKey(): string {
        return "AFFILIATE-CONTACT";
    }

    protected setFromObj(data: IAffiliateContact): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._affiliate_id = DataModel.safeGet(data.affiliate_id, this._affiliate_id) as number;
        this._affiliate = DataModel.safeGet(data.afffiliate, this._affiliate);
        this._additional_info = DataModel.safeGet(data.additional_info, this._additional_info);
        this._email = DataModel.safeGet(data.email, this._email);
        this._phone_number = DataModel.safeGet(data.phone_number, this._phone_number);
    }


    protected toObj(): IAffiliateContact {
        return {
            id: this._id,
            name: this._name,
            affiliate_id: Number(this._affiliate_id),
            afffiliate: this._affiliate,
            additional_info: this._additional_info,
            email: this._email,
            phone_number: this._phone_number
        };
    }

    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    set affiliate_id(id: number) {
        this._affiliate_id = id;
    }

    get affiliate_id() {
        return this._affiliate_id;
    }

    get affiliate() {
        return this._affiliate;
    }

    get additional_info() {
        return this._additional_info;
    }

    get email() {
        return this._email;
    }

    get phone_number() {
        return this._phone_number;
    }
}
