import * as React from 'react';
import {connect} from "react-redux";

export interface IReduxComponentWrapper {
    component: React.ComponentType<any>,
    componentPropsCallback: (state: any) => any,
    skipUpdateCallback?: (state: any) => boolean
}

export interface IComponent {
    component?: React.ComponentType<any>,
    componentProps?: any
}

class ReduxComponentWrapper extends React.Component<IReduxComponentWrapper> {
    public render() {

        class Component extends React.Component<IComponent> {
            public render(): React.ReactNode {
                let r: React.ReactNode = (<div />);

                if (this.props.component && this.props.componentProps) {
                    r = React.createElement(this.props.component,
                        {...this.props.componentProps});
                }

                return r;
            }
        }

        const WrappedComponent: React.ComponentType<IComponent> =
            connect<IComponent>((state: any, ownProps: any) => {
                if (this.props.skipUpdateCallback && this.props.skipUpdateCallback(state)) {
                    return {
                        component: this.props.component,
                        componentProps: ownProps
                    };
                }

                const componentProps: any = this.props.componentPropsCallback(state);

                return {
                    component: this.props.component,
                    componentProps: componentProps
                };
            })(Component);

        return (<WrappedComponent />);
    }
}

export default ReduxComponentWrapper;