import React from 'react';
import { ILayout } from "../../common/components/layouts/ILayout";

export default class MainLayout implements ILayout {

    public renderAroundContent(content: React.ReactNode): React.ReactNode {

        return (
            <div className="full-page">
                {content}
            </div>
        );

    }
}