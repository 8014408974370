import * as React from 'react';
import { FormInput, IFormInputProps } from "../FormInput";
import { Label } from 'reactstrap';
import Select from 'react-select';
import { Creatable } from 'react-select';

export interface ISelectInputProps extends IFormInputProps {
    label?: string,
    placeholder?: string,
    error?: string,
    initialValue?: any,
    id?: string,
    isMulti?: boolean,
    creatable?: boolean,
    disabled?: boolean,
    options?: any,
    styles?: any,
    onInputChange?: (event?: any) => any,
    loadOptions?: (event?: any) => any,
    onFocus?: (event?: any) => any
}

export class SelectInput<T extends ISelectInputProps = ISelectInputProps> extends FormInput<T> {

    protected type: string = "select";
    private value: any = null;
    private selectedOption: any = null;

    constructor(props) {
        super(props);

        if (this.props.initialValue) {
            this.selectedOption = this.props.initialValue;
            this.value = this.selectedOption.value;
        }
        this.onChange = this.onChange.bind(this);
    }

    protected renderLabel(): React.ReactNode {
        return (<Label className={`form-control-label ${!this.props.label ? 'd-none ' : ''}` + this.getErrorClass()} for={this.props.id}>{this.props.label}</Label>
        );

    }

    protected renderInput(): React.ReactNode {
        const additionalProps: any = {};

        if (this.props.isMulti) {
            additionalProps['isMulti'] = this.props.isMulti;
        }

        if (this.props.disabled) {
            additionalProps['isDisabled'] = true;
        }

        if (this.props.onInputChange) {
            additionalProps['onInputChange'] = this.props.onInputChange;
        }

        if (this.props.creatable) {
            additionalProps['createOptionPosition'] = "first";
        }

        additionalProps["isClearable"] = true;
        additionalProps["filterOption"] = null;
        additionalProps["styles"] = this.props.styles;
        additionalProps["loadOptions"] = this.props.loadOptions;
        additionalProps["options"] = this.props.options;
        additionalProps["id"] = this.props.id;
        additionalProps["placeholder"] = this.props.placeholder ? this.props.placeholder : "";
        additionalProps["onChange"] = this.onChange;
        additionalProps["onFocus"] = this.props.onFocus;
        additionalProps["onBlur"] = this.props.onBlur;
        additionalProps["className"] = `form-control pl-0 pr-0 pt-0 ` + this.getInputErrorClass();
        additionalProps["type"] = this.type;
        additionalProps["classNamePrefix"] = "react-select";

        return this.props.creatable
            ?
            (<Creatable
                formatCreateLabel={(inputText) => `Search "${inputText}"`}
                {...additionalProps}
            />)
            : (<Select
                {...additionalProps}
            />);
    }

    private onChange(selectedOption: any, actionTaken: any) {
        this.selectedOption = selectedOption;

        // za clearable opcije
        this.value = selectedOption ? selectedOption.value : selectedOption;
        this.setValue(this.value)
        this.props.onChange(selectedOption, actionTaken);
    }

    protected renderError(): React.ReactNode {
        return (<div className={this.getErrorTextErrorClass()}>{(this.error !== null) ? this.error : ""}</div>);
    }

    public getValue(): any {
        return this.value;
    }

    public getReadOnly(val: any): any {
        return val;
    }
}
