export interface IRestDataSourceParams {
    paging?: IRestPagingParam,
    sort?: IRestSortParam,
    filters?: IRestFilterParam[]
}

export interface IRestPagingParam {
    pageNumber: number,
    pageSize: number
}

export interface IRestSortParam {
    attr: string,
    ascending: boolean
}

export interface IRestFilterParam {
    attr: string,
    val: any,
    operator: string
}

export class RestParamsQueryBuilder {
    public static buildRestParams(params: IRestDataSourceParams):any {
        const queryObj:any = {};

        if (params.paging) {
            queryObj.pagination = params.paging.pageNumber + "," + params.paging.pageSize;
        }

        if (params.sort) {
            queryObj.sort = params.sort.attr + ((params.sort.ascending)?'+':'-');
        }

        if (params.filters && params.filters.length) {
            const filtersArr: string[] = [];

            params.filters.forEach((filter: IRestFilterParam) => {
                filtersArr.push(filter.attr + ":" + filter.operator + ":" + filter.val);
            });

            queryObj.filter = filtersArr.join(",");
        }

        return queryObj;
    }
}