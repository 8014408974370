import * as React from "react";

export interface IFormInputProps {
    onBlur?: (event?: any) => any,
    onChange?: (event?: any, actionTaken?: any) => any,
    initialValue?: any,
    filterMode?: boolean,
    readOnly?: boolean
}

export abstract class FormInput<PropsType extends IFormInputProps, StateType extends any = any>
    extends React.Component<PropsType, StateType> {

    private _inputRef: React.RefObject<any> = React.createRef();

    protected error: string | null = null;

    protected abstract renderLabel(): React.ReactNode;

    protected abstract renderInput(): React.ReactNode;

    protected abstract renderError(): React.ReactNode;

    public abstract getValue(): any;

    public abstract getReadOnly(value: any): any;

    public renderReadOnly(): any {

        if (this.getReadOnly(this.getValue()) !== '') {
            return this.getReadOnly(this.getValue());
        } else {
            return this.getReadOnly(this.props.initialValue);
        }
    }

    public componentDidMount() {
        if (this._inputRef.current) {
            this.setValue(this.props.initialValue);
        }
    }

    public setValue(initialValue: any) {
        if (this._inputRef.current) {
            this._inputRef.current.value = (initialValue) ? initialValue as string : "" as string;
        }
    }

    public setError(error: string): void {
        this.error = error;
        this.setState({
            error: 1
        });
    }

    public removeError(): void {
        this.error = null;
        this.setState({
            error: 0
        });
    }

    protected getErrorClass(): string {
        return (this.error !== null) ? 'lt-error' : '';
    }

    protected getInputErrorClass(): string {
        return (this.error !== null) ? 'is-invalid' : '';
    }

    protected getErrorTextErrorClass(): string {
        return (this.error !== null) ? 'invalid-feedback' : '';
    }

    get inputRef(): React.RefObject<any> {
        return this._inputRef;
    }

    protected fullFieldRender(): React.ReactNode {
        const readOnlyNode: React.ReactNode = (
            <>
                {this.renderReadOnly()}
            </>);

        return (
            <>
                {this.renderLabel()} 
                {(this.props.readOnly) ? readOnlyNode : this.renderInput()}
                {this.renderError()}
            </> 
        );
    }

    protected filterFieldRender(): React.ReactNode {
        return (this.renderInput());
    }

    /**
     * @inheritDoc
     */
    public render() {

        if (this.props.filterMode) {
            return this.filterFieldRender();
        }

        return this.fullFieldRender();
    }
}
