import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import HeaderNav from './header/HeaderNav';

class Header extends Component {
  render() {
    return (
      <header className="header">
        <Container fluid>
          <Row>

            <HeaderNav />

          </Row>
        </Container>



      </header>
    );
  }
}

export default Header;
