import * as React from 'react';
import {ILayout} from "../layouts/ILayout";
import {IDestroy} from "./IDestroy";

export type PageType = typeof Page;

/**
 * Abstract class Page.
 *
 * Page covers necessary functionality related to one page to be displayed in
 * application (Layout, Title bar, Url for navigation).
 */
export default abstract class Page<P extends any = any, S extends any = any> extends React.Component<P, S> {

    /**
     * Retrieves page related browser title.
     */
    protected abstract pageTitle(): string;

    /**
     * Renders page specific content.
     */
    protected abstract renderContent(): React.ReactNode;

    /**
     * Retrieves instance of @see{ILayout}.
     */
    protected abstract pageLayout(): ILayout;

    /**
     * Retrieves all destroyable page members.
     */
    protected abstract getDestroyableMembers(): IDestroy[];

    /**
     * @inheritDoc
     */
    public render(): React.ReactNode {
        return this.pageLayout().renderAroundContent(this.renderContent());
    }

    /**
     * @inheritDoc
     */
    public componentDidMount(): void {
        document.title = this.pageTitle();
        window.scrollTo(0, 0); //Go to top
    }

    /**
     * @inheritDoc
     */
    public UNSAFE_componentWillUnmount(): void {
        this.getDestroyableMembers().forEach((destroyable: IDestroy) => {
            destroyable.destroy();
        });
    }
}
