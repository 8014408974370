import { IUserIdentity, IUserIdentityParams } from "../../common/identity/UserIdentity";
import { Role } from "./Role";
//import { User } from "../models/User";
import { AxiosResponse, AxiosError } from "axios";

/**
 * Identity class designed as singleton to be used through whole application
 * and keep current user information.
 */

class Identity implements IUserIdentity {
    private _id?: number;
    private _username?: string;
    private _auth_key?: string;
    private _auth_key_expires_at?: Date;
    private _email?: string;
    private _role: string = Role.GUEST;
    private _affiliate_id: number;
    private _isGuest: boolean = true;
    private _remember_me: boolean;

    public constructor() {
        const cookieInfo: string | null = this.getCookie("user_info_" + process.env.REACT_APP_TITLE_PREFIX);

        if (null != cookieInfo) {
            const userInfo: any = JSON.parse(cookieInfo);
            userInfo.auth_key_expires_at = new Date(userInfo.auth_key_expires_at);
            this.setUserIdentity(userInfo);
        }
    }

    private setCookie(name: string, value: any, expireDate: Date): void {

        const expires: string = "; expires=" + (this._remember_me ? expireDate.toUTCString() : "0");
        document.cookie = name + "=" + (value || "") + ";path=/;" + expires;
    }

    private getCookie(name: string): string | null {
        const nameEQ: string = name + "=";
        const ca: string[] = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    }

    private eraseCookie(name: string): void {
        document.cookie = name + '=; Max-Age=-99999999'
    }


    public setUserIdentity(userIdentityParams: IUserIdentityParams): void {
        this._id = userIdentityParams.id;
        this._auth_key = userIdentityParams.auth_key;
        this._email = userIdentityParams.email;
        this._role = userIdentityParams.role;
        this._username = userIdentityParams.username;
        this._affiliate_id = userIdentityParams.affiliate_id;
        this._auth_key_expires_at = userIdentityParams.auth_key_expires_at;
        this._isGuest = false;
        this._remember_me = userIdentityParams.remember_me;

        this.setCookie('user_info_' + process.env.REACT_APP_TITLE_PREFIX, JSON.stringify(userIdentityParams), this._auth_key_expires_at);

    }


    public eraseUserIdentity(): void {
        this._id = 0;
        this._username = "";
        this._auth_key = "";
        this._auth_key_expires_at = new Date();
        this._email = "";
        this._affiliate_id = 0;
        this._role = Role.GUEST;
        this._isGuest = true;
        this._remember_me = false;

        this.eraseCookie('user_info_' + process.env.REACT_APP_TITLE_PREFIX);
    }

    public getRequestHeaders(): { [key: string]: string } {
        return {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.auth_key
        };
    }

    get id(): number | undefined {
        return this._id;
    }

    get username(): string | undefined {
        return this._username;
    }

    get email(): string | undefined {
        return this._email;
    }

    get auth_key(): string | undefined {
        return this._auth_key;
    }

    get affiliate_id(): number | undefined {
        return this._affiliate_id;
    }

    get role(): string {
        return this._role;
    }

    get auth_key_expires_at(): Date {
        return this._auth_key_expires_at || new Date();
    }

    get isGuest(): boolean {
        return this._isGuest;
    }

    get remember_me(): boolean {
        return this._remember_me;
    }

    set remember_me(remember_me: boolean) {
        this._remember_me = remember_me;
    }

}

export default new Identity();
