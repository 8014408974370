import * as React from 'react';
import { DataProvider } from "../../../model/DataProvider";
import { BasicPaging } from "./paging/BasicPaging";
import { IRestDataSourceParams } from "../../../dataSource/IRestDataSourceParams";
import { BasicSorter } from "./sorter/BasicSorter";
import { BasicFilter } from "./filter/BasicFilter";
import { Table as ReactStrapTable, Row } from 'reactstrap';
import Util from '../../../../components/custom/Util';

export interface ITableProps {
    provider: DataProvider<any>,
    columns: ITableColumn[],
    searchCallback: (params?: IRestDataSourceParams) => void,
    paging?: BasicPaging,
    loader: Object,
}

export interface ITableColumn {
    attr?: string,
    label: React.ReactNode,
    content: (row: any) => React.ReactNode,
    sorter?: BasicSorter,
    filter?: BasicFilter,
    width?: number,
    searchColSize?: number
}

export class TablePage extends React.Component<ITableProps> {

    private hasFilters: boolean = false;

    public UNSAFE_componentWillMount(): void {
        this.reloadData();

        if (this.props.paging) {
            this.props.paging.addReload(() => {
                this.reloadData();
            });
            this.props.paging.addProvider(this.props.provider);
        }

        this.props.columns.forEach((column: ITableColumn) => {
            if (column.sorter && column.attr) {
                column.sorter.attr = column.attr;

                column.sorter.addReload(() => {

                    if (column.attr) {
                        const attr: string = column.attr;

                        this.props.columns.forEach((c: ITableColumn) => {
                            if (c.sorter && c.attr) {
                                if (attr !== c.attr) {
                                    c.sorter.reset();
                                }
                            }
                        });
                    }

                    this.reloadData();
                });
            }

            if (column.filter && column.attr) {
                this.hasFilters = true;

                column.filter.attr = column.attr;

                column.filter.addReload(() => {
                    if (this.props.paging) {
                        this.props.paging.reset();
                    }

                    this.reloadData();
                });
            }
        });
    }

    private reloadData(): void {
        const reloadParams: IRestDataSourceParams = {};

        if (this.props.paging) {
            reloadParams.paging = this.props.paging;
        }

        this.props.columns.forEach((column: ITableColumn) => {
            if (column.sorter) {
                reloadParams.sort = column.sorter.extendSortParams(reloadParams.sort);
            }

            if (column.filter) {
                reloadParams.filters = column.filter.extendFilterParams(reloadParams.filters);
            }
        });

        this.props.searchCallback(reloadParams);
    }

    public render() {

        const headerColumns: React.ReactNode[] = [];
        const filterColumns: React.ReactNode[] = [];

        this.props.columns.forEach((column: ITableColumn, index: number) => {
            headerColumns.push(<th key={'col' + index} scope="col" style={{ width: column.width ? (column.width + '%') : '20%' }}>
                {column.label}
            </th>);

            if (this.hasFilters) {
                if (column.filter) {
                    filterColumns.push(<React.Fragment key={'filter' + index}>{column.filter.render()}</React.Fragment>);
                } else {
                    filterColumns.push(<div key={'filter' + index} />);
                }
            }
        });

        const dataRows: React.ReactNode[] = [];

        this.props.provider.items.forEach((row: any, index: number) => {
            const rowColumns: React.ReactNode[] = [];

            this.props.columns.forEach((column: ITableColumn, index: number) => {
                rowColumns.push(<td style={{ textAlign: (!isNaN(row[column.attr]) && (column.attr == 'available_stock' || column.attr =='weight' || column.attr =='cost_price' || column.attr =='replacement_value') ? 'right' : 'left') }} className="vlb-table-td-field" key={index}>
                    {column.content(row)}
                </td>)
            });

            dataRows.push(<tr key={index}>{rowColumns}</tr>);
        });

        let result;
        if (this.props.provider.loading) {
            if (this.props.loader) {
                result = (
                    <tbody>
                        {Util.tableLoader(this.props.loader[0], this.props.loader[1])}
                    </tbody>
                )
            } else {
                result = []
            }
        } else {
            if (this.props.provider.items.length > 0) {
                result = (
                    <tbody>
                        {dataRows}
                    </tbody>
                )
            } else {
                result = (
                    <tbody>
                        <tr>
                            <td colSpan={this.props.loader ? this.props.loader[0] : 1} className="font-weight-bold">No result</td>
                        </tr>
                    </tbody>
                )
            }
        }

        return (<div>
            <Row className="searchRow">
                {filterColumns}
            </Row>
            <ReactStrapTable hover responsive>
                <thead>
                    <tr>
                        {headerColumns}
                    </tr>
                </thead>
                {result}
            </ReactStrapTable>
            {this.renderPaging()}
        </div>);
    }

    private renderPaging(): React.ReactNode {
        return (this.props.paging) ? this.props.paging.render() : (<div />);
    }
}
