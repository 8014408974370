import * as React from 'react';
import { FormInput, IFormInputProps } from "../FormInput";
import { string } from 'prop-types';
import { Label } from 'reactstrap';

export interface ITextInputProps extends IFormInputProps {
    label?: string,
    placeholder?: string,
    error?: string,
    maxLength?: number,
    id?: string,
    tabIndex?: number,
    autoFocus?: boolean,
    autoComplete?: string,
    disabled?: boolean,
    class?: string,
    showCloseIcon?: boolean,
}

export class TextInput<T extends ITextInputProps = ITextInputProps> extends FormInput<T> {

    protected type: string = "text";

    protected renderLabel(): React.ReactNode {
        return (<Label className={`form-control-label ${!this.props.label ? 'd-none ' : ''}` + this.getErrorClass()} for={this.props.id}>{this.props.label}</Label>
        );

    }

    protected renderInput(): React.ReactNode {
        const additionalProps: any = {};

        if (this.props.maxLength) {
            additionalProps['maxLength'] = this.props.maxLength;
        }

        if (this.props.tabIndex) {
            additionalProps['tabIndex'] = this.props.tabIndex;
        }

        if (this.props.autoFocus) {
            additionalProps['autoFocus'] = this.props.autoFocus;
        }

        if (this.props.autoComplete) {
            additionalProps['autoComplete'] = this.props.autoComplete;
        }

        if (this.props.disabled) {
            additionalProps['disabled'] = true;
        }

        return (<><input ref={this.inputRef}
            id={this.props.id}
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            onBlur={(this.props.onBlur) ? this.props.onBlur : () => {
            }}
            {...additionalProps}
            value={this.props.initialValue}
            onChange={(this.props.onChange) ? this.props.onChange : () => {
            }}
            
            className={`form-control ${this.props.class ? this.props.class : ''}` + this.getInputErrorClass()} type={this.type} />
            {this.props.showCloseIcon && this.getValue() != "" ? (
            <div aria-hidden="true" className="close-icon" onClick={e => {this.setValue(""); this.forceUpdate()}}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg></div>): null}</>);
    }

    protected renderError(): React.ReactNode {
        return (<div className={this.getErrorTextErrorClass()}>{(this.error !== null) ? this.error : ""}</div>);
    }

    public getValue(): any {
        return (this.inputRef.current) ? this.inputRef.current.value : "";
    }

    public getReadOnly(val: any): any {
        return val;
    }
}
